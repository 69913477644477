import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "custom-video" }
const _hoisted_2 = {
  key: 0,
  class: "video-type"
}

import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import VideoCardYComponents from '@/components/VideoCardY.vue';

// 状态管理：从 Vuex 获取状态

export default /*@__PURE__*/_defineComponent({
  __name: 'shotPlayType',
  setup(__props) {

const store = useStore();
const isCorporateViewVisible = computed(() => store.getters['ViewPlan/getIsCorporateViewVisible']);

// 视频卡片数据
const videoCards = [
  {
    id: '400001',
    type: 'broadcasting',
    icon: require('@/assets/classify_pic/shortPlay/爆笑喜剧.jpg'),
    title: '爆笑喜剧',
  },
  {
    id: '400002',
    type: 'broadcasting',
    icon: require('@/assets/classify_pic/shortPlay/都市爱情.jpg'),
    title: '都市爱情',
  },
  {
    id: '400003',
    type: 'broadcasting',
    icon: require('@/assets/classify_pic/shortPlay/功夫动作.jpg'),
    title: '功夫动作',
  },
  {
    id: '400004',
    type: 'broadcasting',
    icon: require('@/assets/classify_pic/shortPlay/古装短剧.jpg'),
    title: '古装短剧',
  },
  {
    id: '400005',
    type: 'broadcasting',
    icon: require('@/assets/classify_pic/shortPlay/惊悚.jpg'),
    title: '惊悚',
  }, 
  {
    id: '400006',
    type: 'broadcasting',
    icon: require('@/assets/classify_pic/shortPlay/科幻.jpg'),
    title: '科幻',
  }, 
  {
    id: '400007',
    type: 'broadcasting',
    icon: require('@/assets/classify_pic/shortPlay/奇幻冒险.jpg'),
    title: '奇幻冒险',
  }, 
] as const;


// 显示 CorporateVideoView 的函数
const showCorporateView = (card: typeof videoCards[number]) => {
  store.commit('ViewPlan/setSelectedTitle', card.title);
  store.commit('ViewPlan/setCurrentScriptType', card.type);
  store.commit('ViewPlan/setVideoData', card);
  store.commit('ViewPlan/setIsCorporateViewVisible', true);
  store.dispatch('ViewPlan/updateScriptType');
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!isCorporateViewVisible.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(videoCards, (card, index) => {
            return _createVNode(VideoCardYComponents, {
              key: index,
              icon: card.icon,
              title: card.title,
              onClick: ($event: any) => (showCorporateView(card))
            }, null, 8, ["icon", "title", "onClick"])
          }), 64))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})