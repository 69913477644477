<template>
    <div ref="scrollContainer" class="form-container" data-simplebar>
        <div class="form-header">
            <img :src="iconPath" alt="icon" class="form-icon" />
            <h2>{{ selectedTitle }}</h2>
            <button class="back-button" @click="goBack">返回</button>
        </div>
        <!-- <p>填写以下相关信息，越详细生成效果越好</p> -->

        <form @submit.prevent="handleSubmit">
            <!-- 相关文档行 -->
            <div class="form-group">
                <label>相关文档</label>
                <div class="reference-upload">
                    <input type="file" ref="relatedDocInput" accept=".doc,.docx" @change="handleFileUpload('doc')"
                        style="display: none;" />
                    <button for="relatedDocInput" class="upload-button" @click="smartFill">上传文档智能填写</button>
                </div>
            </div>

            <!-- 相关名称 -->
            <div class="form-group" v-for="(label, key) in formLabels" :key="key">
                <label :for="key">
                    {{ label }}
                    <span v-if="isRequired(key)" class="required">*</span>
                </label>
                <div class="input-wrapper">
                    <textarea :id="key" v-model="form[key]" :placeholder="getPlaceholder(key)"
                        :class="{ 'input-error': errors[key] }"></textarea>
                </div>
            </div>

            <div class="form-group">
                <label>视频文案</label>
                <div class="reference-upload">
                    <input type="file" ref="fileInput" accept="video/*" @change="handleFileUpload('video')"
                        style="display: none;" />
                    <button type="button" class="upload-button" @click="extractFromVideo">从视频中导出参考文案</button>
                </div>
            </div>
        </form>

    </div>

    <!-- 蒙版 -->
    <div class="gradient-mask" v-show="!isAtBottom"></div>
    <button type="submit" class="submit-button">策划生成</button>
</template>

<script lang="ts" setup>
import { ref, reactive, watch, onMounted, computed, onBeforeUnmount, nextTick } from 'vue';
import { useStore } from 'vuex';
import { BroFormState } from '@/store/modules/FormBroadcasting';  // 假设你已经定义了 FormState
import SimpleBar from 'simplebar'; // 确保已安装 SimpleBar
defineProps<{ selectedTitle: string }>();

const store = useStore();
// 获取icon
const iconPath = computed(() => store.getters['ViewPlan/getVideoIcon']);
// 返回
const emit = defineEmits(['goBack']);
// 返回上一步
const goBack = () => emit('goBack');

// 获取 Vuex 中的状态，并明确类型
const form = computed<BroFormState['form']>(() => store.getters['broadcasting/formData']); // 添加类型注解
const errors = reactive<Record<keyof BroFormState['form'], boolean>>({
    brandName: false,
    productName: false,
    videoDuration: false,
    sellingPoint: false,
    sellingPointIntro: false,
    advertisingStyleTone: false,
    advertisingTheme: false,
    targetAudience: false,
    reference: false,
});


// 表单标签映射
const formLabels: Record<keyof BroFormState['form'], string> = {
    brandName: '品牌名称',
    productName: '产品名称',
    videoDuration: '视频时长',
    sellingPoint: '主打卖点',
    sellingPointIntro: '卖点详情',
    advertisingStyleTone: '广告风格',
    advertisingTheme: '广告主题',
    targetAudience: '目标受众',
    reference: '参考文案',
};

// 必填字段列表
const requiredFields: Array<keyof BroFormState['form']> = ['brandName', 'productName', 'videoDuration', 'sellingPoint'];

// 判断字段是否为必填项
const isRequired = (key: keyof BroFormState['form']) => requiredFields.includes(key);
const getPlaceholder = (key: string) => key === 'videoDuration' ? '单位默认为秒' : '';
// 定义文件上传相关的变量
const uploadedFile = ref<File | null>(null);

// 验证表单字段
const validateForm = () => {
    let isValid = true;

    requiredFields.forEach((field) => {
        if (!form.value[field]) {
            errors[field] = true;
            isValid = false;
        } else {
            errors[field] = false;
        }
    });

    return isValid;
};

// 表单验证逻辑
const handleSubmit = async () => {
    const isValid = validateForm();  // 确保调用 validateForm

    // 特殊的时长验证
    // validateDuration();

    if (!isValid) {
        alert('请填写所有必填项！');
        return;
    }
    // alert('表单提交成功！');
    // 如果验证通过，调用 Vuex 的 submitForm action
    try {
        const response = await store.dispatch('advertising/submitForm'); // 调用 Vuex 中的 submitForm action
        if (response && response.success) { // 检查 response 是否存在以及 success 属性
        } else {
            alert(`生成失败: ${response.message}`);
        }
    } catch (error) {
        alert(`发生错误: ${error}`);
    }
};


// 文件上传处理
const handleFileUpload = (type: string) => (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
        uploadedFile.value = target.files[0] ?? null;  // 如果为 undefined，则设为 null
        console.log(`${type === 'doc' ? 'Related document' : 'Video'} uploaded:`, uploadedFile.value);
    }
};

const smartFill = () => console.log('智能填写功能未开启，敬请期待');
const extractFromVideo = () => console.log('从视频中导出参考文案功能未开启，敬请期待');

const autoResize = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = '12px'; // 先将高度设置为 auto，以重新计算
    const scrollHeight = textarea.scrollHeight; // 获取实际内容的高度

    if (scrollHeight <= 300) {
        // 如果内容高度小于等于 300px，则调整 textarea 高度
        textarea.style.height = `${scrollHeight}px`;
    } else {
        // 如果内容高度超过 300px，则设置高度为 400px，并允许滚动
        textarea.style.height = `${scrollHeight}px`;
        textarea.style.maxHeight = '300px';
        textarea.style.overflow = 'auto';
    }
};

// 自动调整所有 textarea 高度
const adjustTextareaHeights = () => {
    const textareas = document.querySelectorAll('textarea');
    textareas.forEach((textarea) => autoResize(textarea as HTMLTextAreaElement));
};

// 监控 v-model 数据变化，自动调整 textarea 高度
watch(form, adjustTextareaHeights, { deep: true });

const isAtBottom = ref(false);
const scrollContainer = ref<HTMLElement | null>(null);
const simpleBarInstance = ref<SimpleBar | null>(null);
const lastScrollTop = ref(0);

// 检查滚动位置
function checkScrollPosition() {
    let container = simpleBarInstance.value?.getScrollElement();  // 改为 let
    if (container) {
        const scrollTop = container.scrollTop;
        const clientHeight = container.clientHeight;
        const scrollHeight = container.scrollHeight;
        const tolerance = 15;

        console.log("当前滚动位置 scrollTop:", scrollTop);
        console.log("容器可视区域高度 clientHeight:", clientHeight);
        console.log("容器总高度 scrollHeight:", scrollHeight);
        console.log("容差 tolerance:", tolerance);

        // 如果没有滚动条，则直接设置 isAtBottom 为 true
        if (scrollHeight <= clientHeight) {
            console.log("没有滚动条，设置 isAtBottom 为 true");
            isAtBottom.value = true;
        } else if (scrollTop + clientHeight >= scrollHeight - tolerance && scrollTop !== lastScrollTop.value) {
            console.log("已滚动到底部，设置 isAtBottom 为 true");
            isAtBottom.value = true;
        } else {
            console.log("未到达底部，设置 isAtBottom 为 false");
            isAtBottom.value = false;
        }

        lastScrollTop.value = scrollTop;
        console.log("更新 lastScrollTop:", lastScrollTop.value);

        // 在调用结束时释放 container
        container = null;  // 现在可以赋值为 null
    } else {
        isAtBottom.value = true;
        console.warn('容器为空');
    }
}

function handleResize() {
    checkScrollPosition();
}

onMounted(() => {
    adjustTextareaHeights();
    if (scrollContainer.value) {
        simpleBarInstance.value = new SimpleBar(scrollContainer.value);
        const container = simpleBarInstance.value.getScrollElement();
        if (container) {
            // 初始化时检查滚动位置
            checkScrollPosition();

            container.addEventListener('scroll', checkScrollPosition);
            console.log("SimpleBar instance created and scroll event listener added.");
        } else {
            console.warn('Failed to get scroll element from SimpleBar instance');
        }

        // 添加窗口大小调整的监听
        window.addEventListener('resize', handleResize);
    }
});

onBeforeUnmount(() => {
    const container = simpleBarInstance.value?.getScrollElement();
    if (container) {
        container.removeEventListener('scroll', checkScrollPosition);
    }
    // 移除resize监听
    window.removeEventListener('resize', handleResize);
});
</script>



<style lang="scss" scoped>
.form-container {
    position: relative;
    /* 使 form 容器成为定位上下文 */
    text-align: center;
    /* 内容居中对齐 */
    background-color: #2b2e3d;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: white;
    box-sizing: border-box;
    /* 设置最大高度 */
    overflow-y: auto;
    padding: 0;
    border: 0;
    min-height: 580px;
    max-height: 580px;
    padding-bottom: 40px;
}

.form-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.form-icon {
    margin-top: 20px;
    margin-left: 20px;
    width: 50px;
}

h2 {
    flex: 1;
    text-align: center;
    margin: 0;
    font-size: 20px;
    margin-top: 10px;
}

.back-button {
    background-color: transparent;
    border: 1px solid #fff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    padding: 4px 6px;
    font-size: 12px;
    margin-top: 10px;
    margin-right: 20px;
}

.back-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

p {
    margin-bottom: 20px;
    font-size: 10px;
    color: #aaa;
}

.form-group {
    display: flex;
    width: 100%;
    margin-bottom: 5px;
    font-size: 12px;
}

label {
    margin-left: 18px;
    width: 100px;
    margin-right: 10px;
    margin-top: 5px;
    vertical-align: top;
    /* 靠上对齐 */
    text-align: left;
}

.required {
    color: red;
    margin-left: 0px;
    font-size: 12px;
}

.input-wrapper {
    width: 380px;
    margin-right: 10px;
    position: relative;
    /* 确保滚动容器正确定位 */
}

textarea {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: #1f2233;
    color: white;
    box-sizing: border-box;
    resize: none;
    /* 禁用用户手动调整大小 */
    overflow: hidden;
    /* 禁用滚动条 */
    font-size: 12px;
}

.input-error {
    border: 1px solid red;
}



.reference-upload {
    width: 100%;
    display: flex;
    align-items: left;
    gap: 10px;
    border-left: 0px;
}

.upload-button {
    background-color: #1a73e8;
    color: white;
    width: 150px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
}

.upload-button:hover {
    background-color: #135ba1;
}

.gradient-mask {
    position: absolute;
    bottom: 0px;
    /* 定位到底部 */
    width: 25%;
    height: 160px;
    background: linear-gradient(to bottom,
            rgba(0, 0, 0, 0.0),
            /* 更深的黑色 */
            rgb(19, 19, 20)
            /* 完全透明 */
        );
    pointer-events: none;
    z-index: 1;
}


.submit-button {
    position: absolute;
    /* 使用 fixed 定位 */
    bottom: 1%;
    /* 离页面底部的距离 */
    left: 1%;
    /* 离页面右侧的距离 */
    width: 23%;
    padding: 8px;
    background-color: #1a73e8;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 15px;
    margin-top: 5px;
    z-index: 10;
    /* 确保按钮位于其他内容之上 */
}

.submit-button:hover {
    background-color: #135ba1;
}

/* Webkit 滚动条样式（适用于 Chrome、Safari 和 Edge） */
textarea::-webkit-scrollbar {
  width: 8px;
  /* 设置滚动条宽度 */
  height: 8px;
  /* 设置横向滚动条宽度 */
}

textarea::-webkit-scrollbar-track {
  background-color: #2b2e3d;
  /* 滚动条轨道背景 */
  border-radius: 10px;
  /* 轨道圆角 */
}

textarea::-webkit-scrollbar-thumb {
  background-color: #888;
  /* 滚动条的颜色 */
  border-radius: 10px;
  /* 滚动条的圆角 */
  border: 2px solid #2b2e3d;
  /* 滚动条的边框 */
}

textarea::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* 滚动条悬停时的颜色 */
}


</style>