<template>
  <div class="camera-details">
    <!-- 左侧：视频提示词输入 -->
    <div class="video-input">
      <label for="prompt">视频提示词:</label>
      <textarea id="prompt" v-model="videoPrompt" placeholder="输入视频提示词"></textarea>
    </div>

    <!-- 右侧：参考图片展示和生成视频按钮 -->
    <div class="right-section">
      <label for="referenceImage">视频参考图:</label>
      <div class="reference-image">
        <img :src="referenceImage" alt="Reference Image" v-if="referenceImage" crossorigin="anonymous" />
      </div>
      <button @click="handleGenerateVideo" :disabled="isGenerating">
        {{ isGenerating ? '生成中...' : '生成视频' }}
      </button>
    </div>

    <!-- 生成的视频展示 -->
    <div class="generated-video">
      <video :src="generatedVideo ?? undefined" controls></video>
    </div>

    <!-- 自定义弹窗 -->
    <div v-show="showModal" class="modal-overlay">
      <div class="modal">
        <h4>生成视频确认</h4>
        <p>每条视频的费用为¥3，</p>
        <p>您确定要生成视频吗？</p>
        <div class="button-group">
          <button @click="confirmGenerateVideo">确认</button>
          <button @click="cancelGenerateVideo">取消</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const selectedVideo = computed(() => store.state.ViewEdit.selectedVideo);

const videoSegment = computed(() => {
  return store.getters['ViewEdit/getVideoSegmentById'](selectedVideo.value);
});

const generatedVideo = computed(() => {
  return videoSegment.value ? videoSegment.value.videoUrl : null;
});

// 计算属性绑定到 Vuex 中的 videoPrompt
const videoPrompt = computed({
  get: () => store.getters['ViewStoryboard/getVideoPromptById'](selectedVideo.value),
  set: (value) => {
    store.commit('ViewStoryboard/updateRow', {
      id: selectedVideo.value,
      updatedRow: { video_prompt: value }
    });
  }
});

// 计算属性绑定到 Vuex 中的 referenceImage
const referenceImage = computed({
  get: () => store.getters['ViewStoryboard/getImageById'](selectedVideo.value),
  set: (value) => {
    store.commit('ViewStoryboard/updateRow', {
      id: selectedVideo.value,
      updatedRow: { image: value }
    });
  }
});

// isGenerating computed 属性
const isGenerating = computed({
  get() {
    return store.getters['ViewStoryboard/getIsGeneratingById'](selectedVideo.value);
  },
  set(value) {
    store.commit('ViewStoryboard/setIsGenerating', {
      id: selectedVideo.value,
      isGenerating: value
    });
  }
});

// 控制弹窗显示
const showModal = ref(false);

// 监听 selectedVideo 的变化
watch(selectedVideo, (newValue) => {
  // 当 selectedVideo 为空或其他条件不满足时，关闭弹窗
  if (!newValue) {
    showModal.value = false;
  }
});

function handleGenerateVideo() {
  // 提前检查 selectedVideo 和其他依赖项是否有效
  if (!selectedVideo.value) {
    console.error("Selected video is not defined");
    return;
  }

  // 检查是否正在生成，避免重复触发
  if (isGenerating.value) return;

  // 显示确认弹窗
  showModal.value = true;
}

// 确认生成视频
function confirmGenerateVideo() {
  showModal.value = false; // 关闭弹窗

  // 将 isGenerating 设置为 true，表明生成过程开始
  isGenerating.value = true;

  const video_id = videoSegment.value?.video_id;
  const first_frame_image = referenceImage.value;
  const prompt = videoPrompt.value;

  // 检查依赖项是否定义
  if (!video_id || !first_frame_image || !prompt) {
    console.error("Missing required parameters for video generation.");
    isGenerating.value = false; // 重置生成状态
    return;
  }

  // 调用 Vuex 的 generateVideo action
  store.dispatch('ViewEdit/generateVideo', {
    videoPrompt: prompt,
    video_id: video_id,
    first_frame_image: first_frame_image,
  })
    .then(() => {
      console.log("视频生成请求完成");
    })
    .catch((error) => {
      console.error("生成视频失败:", error);
    })
    .finally(() => {
      isGenerating.value = false;
    });
}

// 取消生成视频
function cancelGenerateVideo() {
  showModal.value = false; // 关闭弹窗
}
</script>


<style scoped>
.camera-details {
  display: flex;
  gap: 20px;
  padding: 10px;
  background-color: #6e6a6a;
  align-items: flex-start;
  height: 205px;
  border-radius: 8px;
}

.video-input {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

label {
  font-size: 15px;
  color: white;
}

.video-input textarea {
  width: 250px;
  height: 155px;
  border-radius: 5px;
  resize: none;
}

.right-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* 左对齐 */
  gap: 10px;
}

.reference-image {
  width: auto;
  /* 可根据需要调整宽度 */
  height: 130px;
}

.reference-image img {
  width: auto;
  height: 130px;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.generated-video {
  margin-top: 10px;
}

.generated-video video {
  width: auto;
  height: 150px;
}

/* 弹窗遮罩层 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 弹窗容器 */
.modal {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  /* 可根据需要调整宽度 */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

/* 标题样式 */
.modal h4 {
  text-align: left;
  /* 将标题左对齐 */
  margin: 0;
  font-size: 18px;
  padding-bottom: 5px;
  /* 缩小标题和其他内容的间距 */
}

/* 正文内容样式 */
.modal p {
  margin: 0;
  font-size: 14px;
  padding: 5px 0;
  text-align: center;
  /* 让正文内容居中 */
}

/* 按钮容器 */
.button-group {
  display: flex;
  flex-direction: row;
  /* 按钮上下排列 */
  gap: 10px;
  /* 按钮之间的间距 */
  align-items: center;
  /* 按钮居中对齐 */
  margin-top: 10px;
}

/* 按钮样式 */
.modal button {
  font-size: 12px;
  /* 缩小按钮的字体 */
  padding: 6px 12px;
  /* 缩小按钮的尺寸 */
  width: 80%;
  /* 按钮宽度为父容器的80% */
  cursor: pointer;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

/* 按钮悬停效果 */
.modal button:hover {
  background-color: #0056b3;
}

/* 第二个按钮的样式 */
.modal button:last-child {
  background-color: #6c757d;
}

.modal button:last-child:hover {
  background-color: #5a6268;
}
</style>
