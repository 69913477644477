// store/modules/ViewCopywriting.ts

import store from '@/store';

const state = {
  activeView: 'video settings', // 当前选中的视图
  aspectRatio: '16:9',          // 视频比例
  materialType: '在线素材',        // 素材类型
  voiceTone: '男-宣传片',          // 旁白音调
  bot_id: '7437071629068894264',
  conversation_id: '',
};


const mutations = {

  setConversationId(state: any, conversation_id: string) {
    state.conversation_id = conversation_id;
  },

  setActiveView(state: any, view: string) {
    state.activeView = view;
  },
  setAspectRatio(state: any, ratio: string) {
    state.aspectRatio = ratio;
  },
  setMaterialType(state: any, type: string) {
    state.materialType = type;
  },
  setVoiceTone(state: any, tone: string) {
    state.voiceTone = tone;
  },

};

const actions = {

  async submitCopywriting({ dispatch, commit, state, getters }: { dispatch: any; commit: any; state: any; getters: any }) {

    const formData = store.getters['ViewPlan/getFormData'] as string;
    // 确保 formData 是对象，再使用 JSON.stringify
    const formDataString = JSON.stringify(formData);

    // console.log('formDataString ====', formDataString);

    const currentPageContent = store.getters['ViewPlan/getCurrentPageContent'] as string;
    const combinedContent = {
      formDataString,
      currentPageContent, // 使用从 getter 中获取的 content
      activeView: state.activeView,
      aspectRatio: state.aspectRatio,
      materialType: state.materialType,
      voiceTone: state.voiceTone,
    };

    // 将 combinedContent 对象传递到 ViewStoryboard 模块的 insertPage 方法
    await dispatch('ViewStoryboard/InitStoryboard', { content: combinedContent }, { root: true });
  }

};


const getters = {
  getActiveView: (state: any) => state.activeView,
  getAspectRatio: (state: any) => state.aspectRatio,
  getMaterialType: (state: any) => state.materialType,
  getVoiceTone: (state: any) => state.voiceTone,

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,

};
