<template>
  <div class="view-type-selection">

    <div class="navbar" v-if="!isCorporateViewVisible">
      <!-- 顶部导航栏 -->
      <nav class="top-navbar">
        <span class="nav-link" :class="{ active: activeView === 'ad' }" @click="activeView = 'ad'">
          广告
        </span>
        <span class="nav-link" :class="{ active: activeView === 'promotion' }" @click="activeView = 'promotion'">
          宣传
        </span>
        <span class="nav-link" :class="{ active: activeView === 'animation' }" @click="activeView = 'animation'">
          动画
        </span>
        <span class="nav-link" :class="{ active: activeView === 'short_story' }" @click="activeView = 'short_story'">
          短剧
        </span>
        <span class="nav-link" :class="{ active: activeView === 'trailer' }" @click="activeView = 'trailer'">
          短视频
        </span>
        <span class="nav-link" :class="{ active: activeView === 'ip_creation' }" @click="activeView = 'ip_creation'">
          IP打造
        </span>
      </nav>

      <!-- 内容区域 -->
      <div class="content-area">
        <div v-if="activeView === 'ad'">
          <!-- 广告片内容 -->
          <AdType />
        </div>

        <div v-if="activeView === 'promotion'">
          <!-- 宣传片内容 -->
          <TrailerType />
        </div>

        <div v-if="activeView === 'animation'">
          <!-- 动画片内容 -->
          <CartoonType />
        </div>

        <div v-if="activeView === 'short_story'">
          <!-- 短剧内容 -->
          <shotPlayType />
        </div>

        <div v-if="activeView === 'trailer'">
          <!-- 短视频内容 -->
          <shotVideo />
        </div>

        <div v-if="activeView === 'ip_creation'">
          <!-- IP打造内容 -->
          <ipCreation />
        </div>
      </div>
    </div>

    <div v-else class="vue-form">
      <component :is="currentFormComponent" @goBack="goBack" :selectedTitle="selectedTitle" />
    </div>

  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import AdType from './video_type/AdType.vue';
import TrailerType from './video_type/TrailerType.vue';
import CartoonType from './video_type/CartoonType.vue';
import shotPlayType from './video_type/shotPlayType.vue';
import shotVideo from './video_type/shotVideo.vue';
import ipCreation from './video_type/ipCreation.vue';
import { useStore } from 'vuex';

import trailerVideo from '@/views/workbench/Plan/video_form/trailerVideo.vue';
import advertisingVideo from '@/views/workbench/Plan/video_form/advertisingVideo.vue';
import broadcastingVideo from '@/views/workbench/Plan/video_form/broadcastingVideo.vue';

// 创建一个状态变量，用来存储当前选中的视图
const activeView = ref('ad');
const store = useStore();

const isCorporateViewVisible = computed(() => store.getters['ViewPlan/getIsCorporateViewVisible']);

// 从 Vuex 中获取 selectedType，并断言类型为 keyof formComponentsMap
const selectedType = computed<keyof typeof formComponentsMap>(() => store.getters['ViewPlan/getScriptType']);
const selectedTitle = computed(() => store.getters['ViewPlan/getSelectedTitle']);

// 组件映射
const formComponentsMap = {
  trailer: trailerVideo,
  advertising: advertisingVideo,
  broadcasting: broadcastingVideo,
};


// 当前的表单组件
const currentFormComponent = computed(() => formComponentsMap[selectedType.value] || null);


// 返回上一步，显示导航栏和视频卡片
const goBack = () => {
  store.commit('ViewPlan/setIsCorporateViewVisible', false);
  store.commit('ViewPlan/setSelectedTitle', '');
  store.commit('ViewPlan/setVideoData', null);
};
</script>

<style lang="scss" scoped>
.view-type-selection {
  width: 100%;
  min-height: 97%;
  height: auto;
  padding: 5px;
  border-radius: 8px;
  // background-color: #1F2229;
}

.top-navbar {
  margin-top: 8px;
  display: flex;
  justify-content: center;
}

.nav-link {
  color: #b9e4dc;
  margin: 0 5px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s;
}

.nav-link:hover {
  color: #0CC5B3;
}

.nav-link.active {
  color: #0CC5B3;
  /* 选中时的颜色 */
}

.content-area {
  flex: 1;
  margin: 0;
  margin-top: 10px;
  padding: 0;
  justify-items: center;
}

.vue-form {
  width: 100%;
  height: 100%;
  padding: 0;
  border: 0;
}
</style>
