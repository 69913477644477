import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "plan" }
const _hoisted_2 = { class: "video-type" }
const _hoisted_3 = { class: "plan-main" }
const _hoisted_4 = { class: "video-setting" }

import { ref, onActivated, onDeactivated } from 'vue';
import VideoTypeSelectionView from '@/views/workbench/Plan/VideoTypeSelection.vue';
import PlanwritingMainView from '@/views/workbench/Plan/PlanMainView.vue';
import PlanSetting from '@/views/workbench/Plan/PlanSetting.vue';


// 生命周期钩子

export default /*@__PURE__*/_defineComponent({
  __name: 'PlanView',
  setup(__props) {

onActivated(() => {
  console.log('planView 被激活');
});

onDeactivated(() => {
  console.log('planView 被缓存');
});



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(VideoTypeSelectionView)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(PlanwritingMainView)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(PlanSetting)
    ])
  ]))
}
}

})