import instance from './index'; // 从 api/index.ts 引入封装的 axios 实例

// 生成分镜脚本
export const submitCopywriting = (copywritingData: any) => {
    return instance.post('/api/v2/storyboardGeneration', copywritingData) // 使用封装的 axios 实例提交表单数据
};


// 生成单张图片
export const imgGeneration = (imageDescription: any) => {
    return instance.post('/api/v2/imgGeneration', imageDescription) // 使用封装的 axios 实例提交数据
};


// 调用音频合成接口
export const narrationGeneration = (text: string, referenceId: string) => {
    return instance.get('/api/v2/soundSynthesis', {
        params: {
            text: text,
            reference_id: referenceId
        }
    });
};
