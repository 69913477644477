import { defineComponent as _defineComponent } from 'vue'
import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "video-card" }
const _hoisted_2 = { class: "title-container" }
const _hoisted_3 = {
  alt: "icon",
  class: "video-icon",
  crossorigin: "anonymous"
}


export default /*@__PURE__*/_defineComponent({
  __name: 'VideoCardY',
  props: {
    icon: String,
    title: String
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("img", _hoisted_3, null, 512), [
        [_directive_lazy, __props.icon]
      ]),
      _createElementVNode("p", null, _toDisplayString(__props.title), 1)
    ])
  ]))
}
}

})