import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "custom-video" }
const _hoisted_2 = {
  key: 0,
  class: "video-type"
}

import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import VideoCardYComponents from '@/components/VideoCardY.vue';

// 状态管理：从 Vuex 获取状态

export default /*@__PURE__*/_defineComponent({
  __name: 'shotVideo',
  setup(__props) {

const store = useStore();
const isCorporateViewVisible = computed(() => store.getters['ViewPlan/getIsCorporateViewVisible']);

// 视频卡片数据
const videoCards = [
  {
    id: '500001',
    type: 'broadcasting',
    icon: require('@/assets/classify_pic/shortVideo/服装时尚.jpg'),
    title: '服装时尚',
  },
  {
    id: '500002',
    type: 'broadcasting',
    icon: require('@/assets/classify_pic/shortVideo/家具家电.jpg'),
    title: '家具家电',
  },
  {
    id: '500003',
    type: 'broadcasting',
    icon: require('@/assets/classify_pic/shortVideo/口播种草.jpg'),
    title: '口播种草',
  },
  {
    id: '500004',
    type: 'broadcasting',
    icon: require('@/assets/classify_pic/shortVideo/美妆护肤.jpg'),
    title: '美妆护肤',
  },
  {
    id: '500005',
    type: 'broadcasting',
    icon: require('@/assets/classify_pic/shortVideo/视频饮料.jpg'),
    title: '视频饮料',
  }, 
  {
    id: '500006',
    type: 'broadcasting',
    icon: require('@/assets/classify_pic/shortVideo/体育运动.jpg'),
    title: '运动健康',
  }, 
  {
    id: '500007',
    type: 'broadcasting',
    icon: require('@/assets/classify_pic/shortVideo/中医养生.jpg'),
    title: '中医养生',
  }, 
] as const;


// 显示 CorporateVideoView 的函数
const showCorporateView = (card: typeof videoCards[number]) => {
  store.commit('ViewPlan/setSelectedTitle', card.title);
  store.commit('ViewPlan/setCurrentScriptType', card.type);
  store.commit('ViewPlan/setVideoData', card);
  store.commit('ViewPlan/setIsCorporateViewVisible', true);
  store.dispatch('ViewPlan/updateScriptType');
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!isCorporateViewVisible.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(videoCards, (card, index) => {
            return _createVNode(VideoCardYComponents, {
              key: index,
              icon: card.icon,
              title: card.title,
              onClick: ($event: any) => (showCorporateView(card))
            }, null, 8, ["icon", "title", "onClick"])
          }), 64))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})