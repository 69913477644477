<template>
    <div class="StoryboardTool">
        <!-- 侧边栏 -->
        <div class="sidebar" @click.stop>
            <div v-for="(item, index) in menuItems" :key="index"
                :class="['menu-item', { active: activeIndex === index }]" @click="selectMenu(index)">
                <i :class="item.icon" class="menu-icon"></i>
                <div>{{ item.label }}</div>
            </div>
        </div>

        <!-- 内容区域 -->
        <div class="content-area" v-if="activeContent !== null" ref="contentArea" @click.stop>
            <h2>{{ activeContent.title }}</h2>
            <div class="grid">
                <div class="grid-item" v-for="(character, index) in activeContent.characters" :key="index">
                    <img :src="character.image" class="character-image" />
                    <div>{{ character.name }}</div>
                </div>
            </div>
        </div>
    </div>
</template>


<script lang="ts" setup>
import { ref, onMounted, onUnmounted } from 'vue';

// 菜单项数据，使用 Bootstrap Icons 类名
const menuItems = [
    { label: '角色', icon: 'bi-person-fill' },
    { label: '场景', icon: 'bi-image-fill' },
    { label: '道具', icon: 'bi-wrench' },
    { label: '风格', icon: 'bi-palette-fill' },
    { label: '特效', icon: 'bi-stars' },
    { label: '更多', icon: 'bi-three-dots' },
];

// 每个菜单对应的内容数据
const contents = [
    {
        title: '角色库',
        characters: [
            { name: '角色名称', image: require('@/assets/image.png') },
            { name: '角色名称', image: require('@/assets/user.jpeg') },
            { name: '角色名称', image: require('@/assets/image.png') },
            { name: '新增角色', image: require('@/assets/add.png') },
        ],
    },
    {
        title: '场景库',
        characters: [
            { name: '场景名称', image: require('@/assets/image.png') },
            { name: '场景名称', image: require('@/assets/image.png') },
            { name: '场景名称', image: require('@/assets/image.png') },
            { name: '新增场景', image: require('@/assets/add.png') },
        ],
    }, {
        title: '道具库',
        characters: [
            { name: '道具名称', image: require('@/assets/user.jpeg') },
            { name: '道具名称', image: require('@/assets/user.jpeg') },
            { name: '道具名称', image: require('@/assets/user.jpeg') },
            { name: '新增道具', image: require('@/assets/add.png') },
        ],
    }, {
        title: '风格库',
        characters: [
            { name: '风格名称', image: require('@/assets/user.jpeg') },
            { name: '风格名称', image: require('@/assets/user.jpeg') },
            { name: '风格名称', image: require('@/assets/user.jpeg') },
            { name: '新增风格', image: require('@/assets/add.png') },
        ],
    }, {
        title: '特效库',
        characters: [
            { name: '特效名称', image: require('@/assets/image.png') },
            { name: '特效名称', image: require('@/assets/image.png') },
            { name: '特效名称', image: require('@/assets/image.png') },
            { name: '新增特效', image: require('@/assets/add.png') },
        ],
    },
    {
        title: '更多',
        characters: [
            { name: '敬请期待', image: require('@/assets/add.png') },
        ],
    },
];

const activeIndex = ref<number | null>(null); // 当前激活的菜单索引
const activeContent = ref<any | null>(null); // 当前显示的内容
const contentArea = ref<HTMLElement | null>(null); // 用于引用 content-area

// 选择菜单项
const selectMenu = (index: number) => {
    activeIndex.value = index;
    activeContent.value = contents[index];
};

// 全局点击事件处理
const handleClickOutside = (event: MouseEvent) => {
    if (contentArea.value && !contentArea.value.contains(event.target as Node)) {
        hideContent(); // 只有点击不在 content-area 内部时，才隐藏内容
    }
};

// 生命周期钩子：组件挂载和卸载时管理全局事件
onMounted(() => {
    window.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
    window.removeEventListener('click', handleClickOutside);
});


// 隐藏内容区域
const hideContent = () => {
    activeIndex.value = null;
    activeContent.value = null;
};
</script>

<style scoped lang="scss">
.StoryboardTool {
    position: relative; /* 为内部绝对定位的元素提供参照 */
    display: flex;
    height: 83vh; /* 占满视口高度 */
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
    background-color: #1e1f29;
    border-radius: 20px;
    width: 70px;
    font-size: 15px;
}

.sidebar {
    width: 50px;
    background-color: #2b2e3d;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    gap: 10px;
    border-radius: 10px;
}

.menu-item {
    border-radius: 10px;
    width: 100%;
    padding: 10px 0;
    text-align: center;
    cursor: pointer;
    color: white;
    transition: background-color 0.3s, color 0.3s;
}

.menu-item:hover {
    background-color: #3a3f4b;
}

.menu-item.active {
    background-color: #4e5a65;
    color: aqua;
}

.menu-icon {
    font-size: 30px;
    margin-bottom: 5px;
}

/* 悬浮的 content-area 样式 */
.content-area {
    margin-top: 8vh;
    margin-left: 50px;
    position: absolute; /* 绝对定位实现悬浮效果 */
    width: 35vh; /* 可根据需要调整 */
    padding: 10px;
    background-color: #4e515e;
    color: white;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* 阴影提升悬浮感 */
    overflow-y: auto;
    max-height: 80vh; /* 确保不会超过视口高度 */
    z-index: 1000; /* 保证在最上层 */
}

.grid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 10px;
}

.grid-item {
    display: flex;
    flex-direction: column;
    background-color: #6a6f8b;
    padding: 10px;
    border-radius: 8px;
}

h2{
    margin-left: 20px;
}

.character-image {
    width: 80px;
    height: auto;
    border-radius: 8px;
    margin-bottom: 5px;
}
</style>
