import instance from './index'; // 从 api/index.ts 引入封装的 axios 实例

// 获取所有音色数据的接口
export const getSoundProfiles = () => {
    return instance.get('/api/get_sound_profiles'); // 假设后端提供这个接口
};


// 获取用户配音记录的接口
export const getVoiceoverByUser = (userId: string, voiceType: string) => {
    return instance.get('/api/v2/getVoiceoverByUser', {
        params: {
            user_id: userId,
            sound_id: voiceType,
        },
    });
};

// 生成配音音频并存储记录的接口
export const voiceoverSynthesis = (data: {
    text: string;
    sound_id: string;
    user_id: string;
    username: string;
}) => {
    return instance.post('/api/v2/voiceoverSynthesis', data, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
};