import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "voiceover-page" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "text-area" }
const _hoisted_4 = ["maxlength"]
const _hoisted_5 = { class: "character-selection" }
const _hoisted_6 = { class: "voice-list" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "voice-info" }
const _hoisted_10 = { class: "voice-description" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "voiceover-output" }
const _hoisted_13 = { class: "voiceover-item" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "voiceover-details" }
const _hoisted_16 = { class: "voiceover-script" }
const _hoisted_17 = ["src"]

import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex';


// 获取 Vuex store

export default /*@__PURE__*/_defineComponent({
  __name: 'VoiceoverComponent',
  setup(__props) {

const store = useStore()

// 文案内容
// 使用 computed 双向绑定 Vuex 中的 scriptText
const scriptText = computed({
    // 通过 get 获取 Vuex 中的 scriptText
    get: () => store.getters['Cvoiceover/getScriptText'],

    // 通过 set 更新 Vuex 中的 scriptText
    set: (newText) => {
        store.commit('Cvoiceover/setScriptText', newText) // 提交 mutation 更新 scriptText
    }
})

const maxLength = computed(() => store.getters['Cvoiceover/getMaxLength'])

// 从 Vuex 中获取音色数据和选中的音色
const soundProfiles = computed(() => store.getters['Cvoiceover/getSoundProfiles'])
const selectedVoice = computed(() => store.getters['Cvoiceover/getSelectedVoice'])

// 获取当前用户ID
const userId = computed(() => store.getters['auth/userID']);

// 播放状态
const playingAudioId = ref<number | null>(null) // 当前播放的音频 ID
let audio: HTMLAudioElement | null = null

// 获取当前选中音色的配音记录
const generatedVoiceovers = computed(() => {
    // 从 Vuex 获取 voiceoverRecords 中的记录
    const soundId = store.getters['Cvoiceover/getConvertedSoundId']
    console.log(soundId);

    return store.getters['Cvoiceover/getVoiceoverRecords'](soundId) || []
})

console.log(` 当前选中音色的配音记录${generatedVoiceovers.value}`);


// 监听 selectedVoice 和 userId 的变化
watch([selectedVoice, userId], async ([newSelectedVoice, newUserId]) => {
    if (newSelectedVoice && newUserId) {
        console.log(`Selected voice changed to ${newSelectedVoice}, checking if voiceover data is needed.`);
        await store.dispatch('Cvoiceover/fetchVoiceoverIfNeeded', { userId: newUserId, soundId: newSelectedVoice });
        // 打印当前的 voiceoverRecords
        console.log('Current voiceoverRecords:', store.state.Cvoiceover.voiceoverRecords);
    }
}, { immediate: true });  // 确保初始化时触发一次

const selectVoice = (id: number) => {
    // 如果点击的音色 ID 与当前选中的音色 ID 相同，则取消选择
    if (store.getters['Cvoiceover/getSelectedVoice'] === id) {
        store.commit('Cvoiceover/setSelectedVoice', null) // 取消选择，将 selectedVoice 设置为空
    } else {
        // 更新选中的音色 ID
        store.commit('Cvoiceover/setSelectedVoice', id)
    }
}


// 缓存音频对象
const cachedAudios = ref<{ [key: number]: HTMLAudioElement }>({})

// 播放示例配音
const playVoiceSample = (voice: any) => {
    selectVoice(voice.id)

    // 如果当前点击的音频是正在播放的音频
    if (playingAudioId.value === voice.id && audio) {
        audio.pause()
        playingAudioId.value = null
    } else {
        // 停止当前播放的音频
        if (audio) audio.pause()

        // 检查音频 URL 是否有效
        const sampleUrl = voice.voice_sample
        if (sampleUrl) {
            // 如果该音频已经缓存，直接使用缓存的音频对象
            if (cachedAudios.value[voice.id]) {
                audio = cachedAudios.value[voice.id]
            } else {
                // 否则创建一个新的 Audio 对象，并缓存它
                audio = new Audio(sampleUrl)
                audio.crossOrigin = 'anonymous'  // 设置跨域属性
                audio.preload = 'auto' // 开始预加载音频
                cachedAudios.value[voice.id] = audio // 缓存音频对象
            }

            // 播放音频
            audio.play().then(() => {
                playingAudioId.value = voice.id
            }).catch((error) => {
                console.error('音频播放失败', error)
            })

            // 播放结束时重置状态
            audio.onended = () => {
                playingAudioId.value = null
            }
        } else {
            console.error('音频 URL 无效')
        }
    }
}

// 生成配音方法
const generateVoiceover = async () => {
    if (!scriptText.value.trim()) {
        alert("请填写配音文案")
        return
    }
    if (!selectedVoice.value) {
        alert("请选择一个音色")
        return
    }
    try {

        // 调用 Vuex 的 createVoiceover action
        await store.dispatch('Cvoiceover/createVoiceover')
        // alert("配音生成成功")
    } catch (error) {
        console.error("生成配音出错:", error)
        alert("生成配音时出错，请重试")
    }
}

// 加载音色数据
onMounted(() => {
    store.dispatch('Cvoiceover/fetchSoundProfiles') // 调用 Vuex action 获取音色数据
})



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "separate" }, null, -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[1] || (_cache[1] = _createElementVNode("h2", null, "配音文案", -1)),
          _withDirectives(_createElementVNode("textarea", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((scriptText).value = $event)),
            maxlength: maxLength.value,
            placeholder: "请输入配音文案..."
          }, null, 8, _hoisted_4), [
            [_vModelText, scriptText.value]
          ]),
          _createElementVNode("p", null, _toDisplayString(scriptText.value.length) + "/" + _toDisplayString(maxLength.value), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[2] || (_cache[2] = _createElementVNode("h2", null, "选择音色", -1)),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(soundProfiles.value, (voice) => {
              return (_openBlock(), _createElementBlock("div", {
                key: voice.id,
                class: _normalizeClass(["voice-item", { selected: selectedVoice.value === voice.id }]),
                onClick: ($event: any) => (selectVoice(voice.id))
              }, [
                _createElementVNode("img", {
                  src: voice.icon,
                  alt: "Voice Avatar",
                  crossorigin: "anonymous"
                }, null, 8, _hoisted_8),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("p", null, _toDisplayString(voice.sound_name), 1),
                  _createElementVNode("p", _hoisted_10, _toDisplayString(voice.description), 1)
                ]),
                _createElementVNode("i", {
                  onClick: _withModifiers(($event: any) => (playVoiceSample(voice)), ["stop"]),
                  class: _normalizeClass(["btn btn-outline-secondary", playingAudioId.value === voice.id ? 'bi-pause-fill' : 'bi-play-fill'])
                }, null, 10, _hoisted_11)
              ], 10, _hoisted_7))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _cache[3] || (_cache[3] = _createElementVNode("h2", null, "配音成品", -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(generatedVoiceovers.value, (voiceover) => {
            return (_openBlock(), _createElementBlock("div", {
              key: voiceover.id,
              class: "voiceover-container"
            }, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("img", {
                  src: voiceover.icon,
                  alt: "配音图片",
                  class: "voiceover-image",
                  crossorigin: "anonymous"
                }, null, 8, _hoisted_14),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("p", null, _toDisplayString(voiceover.sound_name + '-' + voiceover.formatted_created_at), 1),
                  _createElementVNode("p", _hoisted_16, _toDisplayString(voiceover.script_text), 1)
                ])
              ]),
              _createElementVNode("audio", {
                src: voiceover.audio_url,
                controls: "",
                crossorigin: "anonymous"
              }, null, 8, _hoisted_17)
            ]))
          }), 128)),
          _createElementVNode("button", { onClick: generateVoiceover }, "生成")
        ])
      ])
    ])
  ], 64))
}
}

})