<template>
  <div class="custom-video">
    <!-- 根据状态条件渲染 VideoCard 或相应的表单 -->
    <div v-if="!isCorporateViewVisible" class="video-type">
      <VideoCardComponents 
        v-for="(card, index) in videoCards" 
        :key="index" 
        :icon="card.icon" 
        :title="card.title"
        @click="showCorporateView(card)" 
      />
    </div>

    <!-- <div v-else class="vue-form">
      <component :is="currentFormComponent" @goBack="goBack" :selectedTitle="selectedTitle" />
    </div> -->

    
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import VideoCardComponents from '@/components/VideoCard.vue';

// 状态管理：从 Vuex 获取状态
const store = useStore();
const isCorporateViewVisible = computed(() => store.getters['ViewPlan/getIsCorporateViewVisible']);

// 视频卡片数据
const videoCards = [
  {
    id: '200001',
    type: 'trailer',
    icon: require('@/assets/classify_pic/trailer/城市形象.jpg'),
    title: '城市形象',
  },
  {
    id: '200002',
    type: 'trailer',
    icon: require('@/assets/classify_pic/trailer/非遗文化.jpg'),
    title: '非遗文化',
  },
  {
    id: '200003',
    type: 'trailer',
    icon: require('@/assets/classify_pic/trailer/工业制造.jpg'),
    title: '工业制造',
  },
  {
    id: '200004',
    type: 'trailer',
    icon: require('@/assets/classify_pic/trailer/科技生物.jpg'),
    title: '科技生物',
  },
  {
    id: '200005',
    type: 'trailer',
    icon: require('@/assets/classify_pic/trailer/企业形象.jpg'),
    title: '企业形象',
  },
  {
    id: '200006',
    type: 'trailer',
    icon: require('@/assets/classify_pic/trailer/乡村振兴.jpg'),
    title: '乡村振兴',
  },
  {
    id: '200007',
    type: 'trailer',
    icon: require('@/assets/classify_pic/trailer/学校宣传.jpg'),
    title: '学校宣传',
  },  
] as const;


// 显示 CorporateVideoView 的函数
const showCorporateView = (card: typeof videoCards[number]) => {
  store.commit('ViewPlan/setSelectedTitle', card.title);
  store.commit('ViewPlan/setCurrentScriptType', card.type);
  store.commit('ViewPlan/setVideoData', card);
  store.commit('ViewPlan/setIsCorporateViewVisible', true);
  store.dispatch('ViewPlan/updateScriptType');
};

</script>

<style lang="scss" scoped>
.custom-video {
  flex-wrap: wrap;
  max-width: 100%;
  box-sizing: border-box;
  min-height: 540px;
  max-height: 540px !important;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
}

.video-type {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  /* 水平居中 */
  gap: 0px;
  /* 卡片之间的间距 */
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
}

/* Webkit 滚动条样式（适用于 Chrome、Safari 和 Edge） */
.custom-video::-webkit-scrollbar {
  width: 8px;
  /* 设置滚动条宽度 */
  height: 8px;
  /* 设置横向滚动条宽度 */
}

.custom-video::-webkit-scrollbar-track {
  background-color: #2b2e3d;
  /* 滚动条轨道背景 */
  border-radius: 10px;
  /* 轨道圆角 */
}

.custom-video::-webkit-scrollbar-thumb {
  background-color: #888;
  /* 滚动条的颜色 */
  border-radius: 10px;
  /* 滚动条的圆角 */
  border: 2px solid #2b2e3d;
  /* 滚动条的边框 */
}

.custom-video::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* 滚动条悬停时的颜色 */
}


</style>