import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "custom-video" }
const _hoisted_2 = {
  key: 0,
  class: "video-type"
}

import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import VideoCardComponents from '@/components/VideoCard.vue';

// 状态管理：从 Vuex 获取状态

export default /*@__PURE__*/_defineComponent({
  __name: 'TrailerType',
  setup(__props) {

const store = useStore();
const isCorporateViewVisible = computed(() => store.getters['ViewPlan/getIsCorporateViewVisible']);

// 视频卡片数据
const videoCards = [
  {
    id: '200001',
    type: 'trailer',
    icon: require('@/assets/classify_pic/trailer/城市形象.jpg'),
    title: '城市形象',
  },
  {
    id: '200002',
    type: 'trailer',
    icon: require('@/assets/classify_pic/trailer/非遗文化.jpg'),
    title: '非遗文化',
  },
  {
    id: '200003',
    type: 'trailer',
    icon: require('@/assets/classify_pic/trailer/工业制造.jpg'),
    title: '工业制造',
  },
  {
    id: '200004',
    type: 'trailer',
    icon: require('@/assets/classify_pic/trailer/科技生物.jpg'),
    title: '科技生物',
  },
  {
    id: '200005',
    type: 'trailer',
    icon: require('@/assets/classify_pic/trailer/企业形象.jpg'),
    title: '企业形象',
  },
  {
    id: '200006',
    type: 'trailer',
    icon: require('@/assets/classify_pic/trailer/乡村振兴.jpg'),
    title: '乡村振兴',
  },
  {
    id: '200007',
    type: 'trailer',
    icon: require('@/assets/classify_pic/trailer/学校宣传.jpg'),
    title: '学校宣传',
  },  
] as const;


// 显示 CorporateVideoView 的函数
const showCorporateView = (card: typeof videoCards[number]) => {
  store.commit('ViewPlan/setSelectedTitle', card.title);
  store.commit('ViewPlan/setCurrentScriptType', card.type);
  store.commit('ViewPlan/setVideoData', card);
  store.commit('ViewPlan/setIsCorporateViewVisible', true);
  store.dispatch('ViewPlan/updateScriptType');
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!isCorporateViewVisible.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(videoCards, (card, index) => {
            return _createVNode(VideoCardComponents, {
              key: index,
              icon: card.icon,
              title: card.title,
              onClick: ($event: any) => (showCorporateView(card))
            }, null, 8, ["icon", "title", "onClick"])
          }), 64))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})