// src/store/modules/ViewStoryboard.ts
import { submitCopywriting, imgGeneration, narrationGeneration } from '@/api/storyboard'; // 引入封装的 API 方法
import store from '@/store'; // 引入 Vuex 实例


// 定义表格数据类型
export interface TableRow {
    id: string;
    index: number;
    image: string;
    image_prompt: string;
    content: string;
    voiceover: string;
    shot_size: string;
    camera_motion: string;
    character: string;
    scene: string;
    camera_angle: string;
    duration: string;
    style: string;
    video_prompt: string;
    isGenerating: boolean;
}

// 定义默认的 TableRow 值
const DEFAULT_TABLE_ROW: TableRow = {
    id: '',
    index: 0,
    image: require('@/assets/noImage.webp'),
    image_prompt: '',
    content: '正在加载',
    voiceover: '',
    shot_size: '',
    camera_motion: '',
    character: '',
    scene: '',
    camera_angle: '',
    duration: '5',
    style: '',
    video_prompt: '',
    isGenerating: true,
};

// 修改后的数据转换函数，用于处理单个 TableRow 对象
function parseDataToTableRow(item: Partial<TableRow>): TableRow {
    return { ...DEFAULT_TABLE_ROW, ...item }; // 合并默认值和解析后的数据
}

// 定义 state
const state = {
    tableData: <TableRow[]>(
        [
            {
                id: '200001',
                index: 1,
                image: require('@/assets/sun.jpeg'),
                image_prompt: '图像中包含年轻夫妇在驾驶小米SU7的场景，镜头从车辆侧面捕捉，展示车辆穿梭在城市中的流畅性，背景音乐节奏明快，突出城市的活力',
                content: '年轻夫妇驾驶小米SU7在城市中穿梭',
                voiceover: '轻触方向盘，城市脉搏尽在掌握',
                shot_size: '全景',
                camera_motion: '跟拍镜头',
                character: '年轻夫妇',
                scene: '城市街道',
                camera_angle: '侧面（45°）',
                duration: '5',
                style: '现代都市风',
                video_prompt: '视频中包含年轻夫妇在驾驶小米SU7的场景，镜头从车辆侧面捕捉，展示车辆穿梭在城市中的流畅性，背景音乐节奏明快，突出城市的活力。',
                isGenerating: false,
            },
            {
                id: '300002',
                index: 2,
                image: require('@/assets/moon.jpg'),
                image_prompt: '图像中展示商务精英在车内通过高科技设备进行视频会议的场景，镜头聚焦于人物和屏幕交互的细节，背景音乐沉稳大气，强调智能座舱的便利',
                content: '商务精英在车内进行视频会议',
                voiceover: '移动办公室随心所欲',
                shot_size: '中景',
                camera_motion: '前推镜头',
                character: '商务精英',
                scene: '车内',
                camera_angle: '正侧面',
                duration: '5',
                style: '商务风',
                video_prompt: '视频中展示商务精英在车内通过高科技设备进行视频会议的场景，镜头聚焦于人物和屏幕交互的细节，背景音乐沉稳大气，强调智能座舱的便利',
                isGenerating: false,
            },

        ] // 初始化为空数组
    ),
    ratio: '16:9', // 图片比例
};

// 在 Vuex 模块初始化时检查 tableData，如果为空则添加默认值
if (state.tableData.length === 0) {
    state.tableData.push({ ...DEFAULT_TABLE_ROW });
}

// 定义 mutations
const mutations = {

    // 根据 id 设置 isGenerating 的值
    setIsGenerating(state: { tableData: TableRow[] }, { id, isGenerating }: { id: string; isGenerating: boolean }) {
        const rowIndex = state.tableData.findIndex((row) => row.id === id);
        if (rowIndex !== -1) {
            // 找到对应的行并更新 isGenerating 值
            state.tableData[rowIndex].isGenerating = isGenerating;
        } else {
            console.error(`Row with id ${id} not found.`);
        }
    },

    setTableData(state: { tableData: TableRow[] }, data: TableRow[]) {
        state.tableData = data; // 直接覆盖现有的 tableData
    },

    // 添加新行
    addRow(state: { tableData: TableRow[] }, payload: { newRow: TableRow; insertIndex: number }) {
        const { newRow, insertIndex } = payload;
        state.tableData.splice(insertIndex, 0, newRow);
        mutations.updateIndexes(state); // 调整索引
    },

    updateRow(
        state: { tableData: TableRow[] },
        { id, updatedRow }: { id: string; updatedRow: Partial<TableRow> }
    ) {
        const rowIndex = state.tableData.findIndex((row) => row.id === id);
        if (rowIndex !== -1) {
            // 直接修改行数据
            Object.assign(state.tableData[rowIndex], updatedRow);

            // 浅克隆整个数组以触发响应式更新
            state.tableData = [...state.tableData];
        } else {
            console.error(`Error: Row with id "${id}" not found.`);
            throw new Error(`Row with id "${id}" not found.`);
        }
        console.log(state.tableData);
    },


    // 删除行
    deleteRow(state: { tableData: TableRow[] }, id: string) {
        const rowIndex = state.tableData.findIndex((row) => row.id === id);
        if (rowIndex !== -1) {
            state.tableData.splice(rowIndex, 1);
            mutations.updateIndexes(state); // 调整索引
        }
    },

    // 更新所有行的索引
    updateIndexes(state: { tableData: TableRow[] }) {
        state.tableData.forEach((row, index) => {
            row.index = index + 1;
        });
    },
    // 设置图片比例
    setRatio(state: { ratio: string }, ratio: string) {
        state.ratio = ratio;
    },

};

// 定义 actions
const actions = {
    addRowAction({ commit }: { commit: Function }, { newRow, insertIndex }: { newRow: TableRow; insertIndex: number }) {
        commit('addRow', { newRow, insertIndex });
    },
    updateRowAction({ commit }: { commit: Function }, payload: { id: string; updatedRow: Partial<TableRow> }) {
        commit('updateRow', payload);
    },
    deleteRowAction({ commit }: { commit: Function }, id: string) {
        commit('deleteRow', id);
    },

    // 合成旁白音频
    async generateVoiceoverAction({ state, rootGetters }: { state: any, rootGetters: any }) {

        console.log("合成旁白音频中")

        const voiceTone = rootGetters['View/getVoiceTone']; // 调用其他模块的 getter

        console.log(voiceTone)

        console.log(`当前语音音调: ${voiceTone}`);

        // 根据不同的 voiceTone 选择对应的 reference_id
        let reference_id: string;
        switch (voiceTone) {
            case '男-宣传片':
                reference_id = '2e35ecf6cc4e49398196007aeb48c46b'; // 沉稳大气
                break;
            case '女-温柔知性':
                reference_id = '8d232da29c924bbabba75ec23343be0d'; // 女-温柔知性
                break;
            case '男-叙事':
                reference_id = '4f8beeb6daf149af95d2fb3e3ca631b2'; // 男-叙事
                break;
            case '男-新闻广播':
                reference_id = '0a5adebb3d6d4aa7ac0f59c71b872208'; // 男-新闻广播
                break;
            case '男-科技':
                reference_id = '0d741e5aa643425cbece49f9824d18f0'; // 男-科技
                break;
            case '男-讲故事2':
                reference_id = '34682299301c413daa8db617fabae70a'; // 男-讲故事2
                break;
            case '男-讲故事':
                reference_id = 'f1e5000739364721812349f6ab41580f'; // 男-讲故事
                break;
            case '男-房地产':
                reference_id = '5d76f81bb7b54aec9b337da3cf0aa67e'; // 男-房地产
                break;
            case '男-MG动画':
                reference_id = 'f97bd279b0954e0990184b43b28a498a'; // 男-MG动画
                break;
            default:
                reference_id = '2e35ecf6cc4e49398196007aeb48c46b'; // 默认值
                break; 
        }


        // 提取所有 voiceover，并拼接成一个完整的字符串
        const combinedVoiceover = state.tableData
            .map((row: TableRow) => row.voiceover)
            .filter((voiceover: string) => voiceover.trim() !== '') // 过滤掉空的旁白
            .join(' '); // 拼接旁白，用空格分隔

        console.log("旁白详情:", combinedVoiceover);


        // 调用音频生成 API
        try {
            // 调用音频生成 API
            const response = await narrationGeneration(combinedVoiceover, reference_id);

            // 从响应中提取生成的音频文件 URL 和时长
            const fileUrl = response.data.file_url;
            const audioDuration = response.data.duration; // 获取音频时长
            console.log("获得的旁白音频:", fileUrl);
            console.log("旁白音频时长:", audioDuration);

            // 将 fileUrl 和时长存储在 Vuex 或其他变量中
            store.commit('ViewEdit/setGeneratedAudioUrl', fileUrl); // 假设有对应的 mutation 来存储 URL
            store.commit('ViewEdit/setGeneratedAudioDuration', audioDuration); // 假设有对应的 mutation 来存储时长

            console.log("合成旁白音频完成")

        } catch (error) {
            console.error("Failed to generate voiceover:", error);
        }
    },


    // 为指定的行生成图片，并更新该行的图片 URL
    async generateImageForRowAction(
        { commit, state }: { commit: Function; state: any },
        id: string
    ) {
        // 找到指定行的数据
        const row = state.tableData.find((row: TableRow) => row.id === id);
        const ratio = state.ratio || '16:9'; // 如果 state.ratio 为空，则使用 '16:9' 作为默认比例
        if (row) {
            try {
                // 设置 isGenerating 为 true，表示正在生成中
                commit('setIsGenerating', { id, isGenerating: true });

                // 调用 imgGeneration API，使用 row.image_prompt 作为 prompt 和 row.ratio 作为比例
                const response = await imgGeneration({
                    prompt: row.image_prompt,
                    ratio: ratio, // 图片比例
                });

                console.log(response.data);
                // 访问返回的数据结构，获取 img_url
                const imageUrl = response.data.data.img_url;
                console.log(imageUrl);

                // 更新该行的 image 字段，将生成的图片 URL 存储到 Vuex
                commit('updateRow', { id, updatedRow: { image: imageUrl } });

            } catch (error) {
                console.error(`生成图片失败, 行 ID: ${id}`, error);
            } finally {
                // 无论是否成功，生成完成后将 isGenerating 设置为 false
                commit('setIsGenerating', { id, isGenerating: false });
            }
        } else {
            console.error(`未找到行 ID: ${id}`);
        }
    },

    async checkAndGenerateImage({ commit, state, dispatch }: { commit: Function; state: any; dispatch: Function }) {
        // 遍历表格数据，检查每一行的 image 字段
        for (const row of state.tableData) {
            // 如果图片为默认的 noImage，则生成新图片
            if (row.image === require('@/assets/noImage.webp')) {
                try {
                    // 调用 generateImageForRowAction 方法生成图片
                    await dispatch('generateImageForRowAction', row.id);
                } catch (error) {
                    console.error(`无法为行 ID: ${row.id} 生成图片`, error);
                }
            }
        }
    },

    async InitStoryboard({ commit, state, dispatch }: { commit: Function; state: any; dispatch: Function }, { content }: { content: any }) {
        // 打印接收到的 combinedContent 数据的每一个参数
        console.log('Received content:');
        for (const [key, value] of Object.entries(content)) {
            console.log(`${key}:`, value);
        }

        // 假设 content 中有一个属性 "aspectRatio" 需要设置为 ratio
        const ratio = content.aspectRatio; // 从 content 中获取比例
        if (ratio) {
            // 使用 Vuex mutation 设置比例
            commit('setRatio', ratio);
        }

        // 调用 submitCopywriting 方法提交内容
        try {
            const response = await submitCopywriting(content);

            // // 示例 response 对象
            // const response = {
            //     success: true,  // 或 false，指示操作成功或失败
            //     data: {
            //         contentId: "123456",  // 提交内容的 ID
            //         message: "提交成功！",
            //         data: "hhhh",
            //         timestamp: "2024-11-08T12:30:00Z"  // 操作的时间戳
            //     },
            //     error: null  // 如果有错误，这里会包含错误信息
            // };

            // 尝试将 response.data.data 解析为 JSON
            let parsedData;
            try {
                parsedData = JSON.parse(response.data.data);
                console.log("Parsed JSON Data:", parsedData);

                // 遍历每一个 item，将其传入 parseDataToTableRow 函数中
                const processedData = parsedData.map((item: Partial<TableRow>) => {
                    return parseDataToTableRow(item);
                });

                console.log("Processed Data:", processedData);

                // 现在你可以用 processedData 去做进一步处理，比如存储到 Vuex 中
                commit('setTableData', processedData);

            } catch (jsonError) {
                // 如果解析失败，则说明 data 不是 JSON 格式，打印 message
                console.log("Message:", response.data.message);
                alert("这个AI机器人的回答好像有些问题，请之后再试吧")
            }
            // 根据需要处理响应，例如显示提示信息或者执行其他操作
        } catch (error) {
            console.error("Failed to submit storyboard:", error);
        }
        // 调用 checkAndGenerateImage 方法，检查并生成图片
        try {
            await dispatch('checkAndGenerateImage');
        } catch (error) {
            console.error("Error during image generation:", error);
        }
    },
};

// 定义 getters
const getters = {
    getTableData: (state: { tableData: TableRow[] }) => state.tableData,
    getRowById: (state: { tableData: TableRow[] }) => (id: string) =>
        state.tableData.find((row) => row.id === id),
    // 获取指定 id 的 image_prompt
    getImagePromptById: (state: { tableData: TableRow[] }) => (id: string) => {
        const row = state.tableData.find((row) => row.id === id);
        return row ? row.image_prompt : null; // 如果找到对应行，则返回 image_prompt，否则返回 null
    },

    // 获取包含 id 和 image_prompt 的字典
    getImagePromptsDictionary: (state: { tableData: TableRow[] }) => {
        return state.tableData.reduce((acc: Record<string, string>, row) => {
            acc[row.id] = row.image_prompt;
            return acc;
        }, {});
    },
    getImageById: (state: { tableData: TableRow[] }) => (id: string) => {
        const row = state.tableData.find(item => item.id === id);
        return row ? row.image : null;
    },
    getVideoPromptById: (state: { tableData: TableRow[] }) => (id: string) => {
        const row = state.tableData.find(item => item.id === id);
        return row ? row.video_prompt : null;
    },
    getIsGeneratingById: (state: { tableData: TableRow[] }) => (id: string) => {
        const row = state.tableData.find(item => item.id === id);
        return row ? row.isGenerating : null;
    },

};

// 导出模块
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};