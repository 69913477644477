import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, withModifiers as _withModifiers, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "form-header" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { class: "reference-upload" }
const _hoisted_5 = ["for"]
const _hoisted_6 = {
  key: 0,
  class: "required"
}
const _hoisted_7 = { class: "input-wrapper" }
const _hoisted_8 = ["id", "onUpdate:modelValue", "placeholder"]
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = { class: "reference-upload" }
const _hoisted_11 = { class: "gradient-mask" }

import { ref, reactive, watch, onMounted, computed, onBeforeUnmount, nextTick } from 'vue';
import { useStore } from 'vuex';
import { TrailerFormState } from '@/store/modules/FormTrailer';  // 假设你已经定义了 TrailerFormState
import SimpleBar from 'simplebar'; // 确保已安装 SimpleBar

export default /*@__PURE__*/_defineComponent({
  __name: 'trailerVideo',
  props: {
    selectedTitle: {}
  },
  emits: ['goBack'],
  setup(__props: any, { emit: __emit }) {



const store = useStore();
// 获取icon
const iconPath = computed(() => store.getters['ViewPlan/getVideoIcon']);
// 返回
const emit = __emit;

// 获取 Vuex 中的状态，并明确类型
const form = computed<TrailerFormState['form']>(() => store.getters['advertising/formData']); // 添加类型注解
const errors = reactive<Record<keyof TrailerFormState['form'], boolean>>({
    companyName: false,
    companyTitle: false,
    videoDuration: false,
    companyIntro: false,
    productIntro: false,
    useCases: false,
    serviceIntro: false,
    advantages: false,
    teamIntro: false,
    contact: false,
    reference: false,
});


// 表单标签映射
const formLabels: Record<keyof TrailerFormState['form'], string> = {
    companyName: '企业名称',
    companyTitle: '企业简称',
    videoDuration: '视频时长',
    companyIntro: '企业简介',
    productIntro: '产品介绍',
    useCases: '使用场景',
    serviceIntro: '服务介绍',
    advantages: '企业优势',
    teamIntro: '团队介绍',
    contact: '联系方式',
    reference: '参考文献',
};

// 必填字段列表
const requiredFields: Array<keyof TrailerFormState['form']> = ['companyName', 'companyTitle', 'videoDuration'];

// 判断字段是否为必填项
const isRequired = (key: keyof TrailerFormState['form']) => requiredFields.includes(key);
const getPlaceholder = (key: string) => key === 'videoDuration' ? '单位为分钟' : '';
// 定义文件上传相关的变量
const uploadedFile = ref<File | null>(null);

// 返回上一步
const goBack = () => emit('goBack');


// 验证表单字段
const validateForm = () => {
    let isValid = true;

    requiredFields.forEach((field) => {
        if (!form.value[field]) {
            errors[field] = true;
            isValid = false;
        } else {
            errors[field] = false;
        }
    });

    return isValid;
};

// 表单验证逻辑
const handleSubmit = async () => {
    const isValid = validateForm();  // 确保调用 validateForm

    if (!isValid) {
        alert('请填写所有必填项！');
        return;
    }

    try {
        const response = await store.dispatch('advertising/submitForm'); // 调用 Vuex 中的 submitForm action
        if (response && response.success) { // 检查 response 是否存在以及 success 属性
        } else {
            alert(`生成失败: ${response.message}`);
        }
    } catch (error) {
        alert(`发生错误: ${error}`);
    }
};


// 文件上传处理
const handleFileUpload = (type: string) => (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
        uploadedFile.value = target.files[0] ?? null;  // 如果为 undefined，则设为 null
        console.log(`${type === 'doc' ? 'Related document' : 'Video'} uploaded:`, uploadedFile.value);
    }
};


const smartFill = () => console.log('智能填写功能未开启，敬请期待');
const extractFromVideo = () => console.log('从视频中导出参考文案功能未开启，敬请期待');

const autoResize = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = '12px'; // 先将高度设置为 auto，以重新计算
    const scrollHeight = textarea.scrollHeight; // 获取实际内容的高度

    if (scrollHeight <= 300) {
        // 如果内容高度小于等于 300px，则调整 textarea 高度
        textarea.style.height = `${scrollHeight}px`;
    } else {
        // 如果内容高度超过 300px，则设置高度为 400px，并允许滚动
        textarea.style.height = `${scrollHeight}px`;
        textarea.style.maxHeight = '300px';
        textarea.style.overflow = 'auto';
    }
};

// 自动调整所有 textarea 高度
const adjustTextareaHeights = () => {
    const textareas = document.querySelectorAll('textarea');
    textareas.forEach((textarea) => autoResize(textarea as HTMLTextAreaElement));
};

// 监控 v-model 数据变化，自动调整 textarea 高度
watch(form, adjustTextareaHeights, { deep: true });

const isAtBottom = ref(false);
const scrollContainer = ref<HTMLElement | null>(null);
const simpleBarInstance = ref<SimpleBar | null>(null);
const lastScrollTop = ref(0);

// 检查滚动位置
function checkScrollPosition() {
    let container = simpleBarInstance.value?.getScrollElement();  // 改为 let
    if (container) {
        const scrollTop = container.scrollTop;
        const clientHeight = container.clientHeight;
        const scrollHeight = container.scrollHeight;
        const tolerance = 15;

        console.log("当前滚动位置 scrollTop:", scrollTop);
        console.log("容器可视区域高度 clientHeight:", clientHeight);
        console.log("容器总高度 scrollHeight:", scrollHeight);
        console.log("容差 tolerance:", tolerance);

        // 如果没有滚动条，则直接设置 isAtBottom 为 true
        if (scrollHeight <= clientHeight) {
            console.log("没有滚动条，设置 isAtBottom 为 true");
            isAtBottom.value = true;
        } else if (scrollTop + clientHeight >= scrollHeight - tolerance && scrollTop !== lastScrollTop.value) {
            console.log("已滚动到底部，设置 isAtBottom 为 true");
            isAtBottom.value = true;
        } else {
            console.log("未到达底部，设置 isAtBottom 为 false");
            isAtBottom.value = false;
        }

        lastScrollTop.value = scrollTop;
        console.log("更新 lastScrollTop:", lastScrollTop.value);

        // 在调用结束时释放 container
        container = null;  // 现在可以赋值为 null
    } else {
        isAtBottom.value = true;
        console.warn('容器为空');
    }
}

function handleResize() {
    checkScrollPosition();
}

onMounted(() => {
    adjustTextareaHeights();
    if (scrollContainer.value) {
        simpleBarInstance.value = new SimpleBar(scrollContainer.value);
        const container = simpleBarInstance.value.getScrollElement();
        if (container) {
            // 初始化时检查滚动位置
            checkScrollPosition();

            container.addEventListener('scroll', checkScrollPosition);
            console.log("SimpleBar instance created and scroll event listener added.");
        } else {
            console.warn('Failed to get scroll element from SimpleBar instance');
        }

        // 添加窗口大小调整的监听
        window.addEventListener('resize', handleResize);
    }
});

onBeforeUnmount(() => {
    const container = simpleBarInstance.value?.getScrollElement();
    if (container) {
        container.removeEventListener('scroll', checkScrollPosition);
    }
    // 移除resize监听
    window.removeEventListener('resize', handleResize);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      ref_key: "scrollContainer",
      ref: scrollContainer,
      class: "form-container",
      "data-simplebar": ""
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: iconPath.value,
          alt: "icon",
          class: "form-icon"
        }, null, 8, _hoisted_2),
        _createElementVNode("h2", null, _toDisplayString(_ctx.selectedTitle), 1),
        _createElementVNode("button", {
          class: "back-button",
          onClick: goBack
        }, "返回")
      ]),
      _createElementVNode("form", {
        onSubmit: _withModifiers(handleSubmit, ["prevent"])
      }, [
        _createElementVNode("div", _hoisted_3, [
          _cache[2] || (_cache[2] = _createElementVNode("label", null, "相关文档", -1)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("input", {
              type: "file",
              ref: "relatedDocInput",
              accept: ".doc,.docx",
              onChange: _cache[0] || (_cache[0] = ($event: any) => (handleFileUpload('doc'))),
              style: {"display":"none"}
            }, null, 544),
            _createElementVNode("button", {
              for: "relatedDocInput",
              class: "upload-button",
              onClick: smartFill
            }, "上传文档智能填写")
          ])
        ]),
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(formLabels, (label, key) => {
          return _createElementVNode("div", {
            class: "form-group",
            key: key
          }, [
            _createElementVNode("label", { for: key }, [
              _createTextVNode(_toDisplayString(label) + " ", 1),
              (isRequired(key))
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, "*"))
                : _createCommentVNode("", true)
            ], 8, _hoisted_5),
            _createElementVNode("div", _hoisted_7, [
              _withDirectives(_createElementVNode("textarea", {
                id: key,
                "onUpdate:modelValue": ($event: any) => ((form.value[key]) = $event),
                placeholder: getPlaceholder(key),
                class: _normalizeClass({ 'input-error': errors[key] })
              }, null, 10, _hoisted_8), [
                [_vModelText, form.value[key]]
              ])
            ])
          ])
        }), 64)),
        _createElementVNode("div", _hoisted_9, [
          _cache[3] || (_cache[3] = _createElementVNode("label", null, "视频文案", -1)),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("input", {
              type: "file",
              ref: "fileInput",
              accept: "video/*",
              onChange: _cache[1] || (_cache[1] = ($event: any) => (handleFileUpload('video'))),
              style: {"display":"none"}
            }, null, 544),
            _createElementVNode("button", {
              type: "button",
              class: "upload-button",
              onClick: extractFromVideo
            }, "从视频中导出参考文案")
          ])
        ])
      ], 32)
    ], 512),
    _withDirectives(_createElementVNode("div", _hoisted_11, null, 512), [
      [_vShow, !isAtBottom.value]
    ]),
    _cache[4] || (_cache[4] = _createElementVNode("button", {
      type: "submit",
      class: "submit-button"
    }, "策划生成", -1))
  ], 64))
}
}

})