import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-material" }
const _hoisted_2 = { class: "material-nav" }
const _hoisted_3 = { class: "content-display" }

import { ref, computed } from 'vue';
import VoiceoverComponent from './VoiceoverComponent.vue';
import MusicComponent from './MusicComponent .vue';
import ImageComponent from './ImageComponent .vue';
import VideoComponent from './VideoComponent .vue';
import DesignComponent from './DesignComponent .vue';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'MaterialMainView',
  setup(__props) {

const store = useStore()

const selectedOption = computed({
  get: () => store.getters['ViewMaterial/getSelectedOption'],
  set: (value: string | null) => {
    store.dispatch('ViewMaterial/updateSelectedOption', value);
  },
});

// Mapping of selected options to their corresponding components
const selectedOptionComponent = computed(() => {
    switch (selectedOption.value) {
        case 'voiceover':
            return VoiceoverComponent;
        case 'music':
            return MusicComponent;
        case 'image':
            return ImageComponent;
        case 'video':
            return VideoComponent;
        case 'design':
            return DesignComponent;
        default:
            return null;
    }
});


// 处理按钮点击并切换选择状态的函数
function setSelectedOption(option: string, event: MouseEvent) {
    // 阻止事件传播到父级元素
    event.stopPropagation();

    // 如果再次点击相同的选项，取消选择（设置为 null）
    if (selectedOption.value === option) {
        selectedOption.value = null;
    } else {
        // 否则，设置为当前选中的选项
        selectedOption.value = option;
    }
}



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("button", {
        class: _normalizeClass({ active: selectedOption.value === 'voiceover' }),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (setSelectedOption('voiceover', $event)))
      }, _cache[5] || (_cache[5] = [
        _createElementVNode("i", { class: "bi bi-mic" }, null, -1),
        _createTextVNode(" 配音 ")
      ]), 2),
      _createElementVNode("button", {
        class: _normalizeClass({ active: selectedOption.value === 'music' }),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (setSelectedOption('music', $event)))
      }, _cache[6] || (_cache[6] = [
        _createElementVNode("i", { class: "bi bi-music-note" }, null, -1),
        _createTextVNode(" 配乐 ")
      ]), 2),
      _createElementVNode("button", {
        class: _normalizeClass({ active: selectedOption.value === 'image' }),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (setSelectedOption('image', $event)))
      }, _cache[7] || (_cache[7] = [
        _createElementVNode("i", { class: "bi bi-image" }, null, -1),
        _createTextVNode(" 图片 ")
      ]), 2),
      _createElementVNode("button", {
        class: _normalizeClass({ active: selectedOption.value === 'video' }),
        onClick: _cache[3] || (_cache[3] = ($event: any) => (setSelectedOption('video', $event)))
      }, _cache[8] || (_cache[8] = [
        _createElementVNode("i", { class: "bi bi-camera-video" }, null, -1),
        _createTextVNode(" 视频 ")
      ]), 2),
      _createElementVNode("button", {
        class: _normalizeClass({ active: selectedOption.value === 'design' }),
        onClick: _cache[4] || (_cache[4] = ($event: any) => (setSelectedOption('design', $event)))
      }, _cache[9] || (_cache[9] = [
        _createElementVNode("i", { class: "bi bi-paint-bucket" }, null, -1),
        _createTextVNode(" 设计 ")
      ]), 2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(selectedOptionComponent.value)))
    ])
  ]))
}
}

})