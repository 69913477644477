import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "StoryboardTool" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "grid" }
const _hoisted_4 = ["src"]

import { ref, onMounted, onUnmounted } from 'vue';

// 菜单项数据，使用 Bootstrap Icons 类名

export default /*@__PURE__*/_defineComponent({
  __name: 'StoryboardTool',
  setup(__props) {

const menuItems = [
    { label: '角色', icon: 'bi-person-fill' },
    { label: '场景', icon: 'bi-image-fill' },
    { label: '道具', icon: 'bi-wrench' },
    { label: '风格', icon: 'bi-palette-fill' },
    { label: '特效', icon: 'bi-stars' },
    { label: '更多', icon: 'bi-three-dots' },
];

// 每个菜单对应的内容数据
const contents = [
    {
        title: '角色库',
        characters: [
            { name: '角色名称', image: require('@/assets/image.png') },
            { name: '角色名称', image: require('@/assets/user.jpeg') },
            { name: '角色名称', image: require('@/assets/image.png') },
            { name: '新增角色', image: require('@/assets/add.png') },
        ],
    },
    {
        title: '场景库',
        characters: [
            { name: '场景名称', image: require('@/assets/image.png') },
            { name: '场景名称', image: require('@/assets/image.png') },
            { name: '场景名称', image: require('@/assets/image.png') },
            { name: '新增场景', image: require('@/assets/add.png') },
        ],
    }, {
        title: '道具库',
        characters: [
            { name: '道具名称', image: require('@/assets/user.jpeg') },
            { name: '道具名称', image: require('@/assets/user.jpeg') },
            { name: '道具名称', image: require('@/assets/user.jpeg') },
            { name: '新增道具', image: require('@/assets/add.png') },
        ],
    }, {
        title: '风格库',
        characters: [
            { name: '风格名称', image: require('@/assets/user.jpeg') },
            { name: '风格名称', image: require('@/assets/user.jpeg') },
            { name: '风格名称', image: require('@/assets/user.jpeg') },
            { name: '新增风格', image: require('@/assets/add.png') },
        ],
    }, {
        title: '特效库',
        characters: [
            { name: '特效名称', image: require('@/assets/image.png') },
            { name: '特效名称', image: require('@/assets/image.png') },
            { name: '特效名称', image: require('@/assets/image.png') },
            { name: '新增特效', image: require('@/assets/add.png') },
        ],
    },
    {
        title: '更多',
        characters: [
            { name: '敬请期待', image: require('@/assets/add.png') },
        ],
    },
];

const activeIndex = ref<number | null>(null); // 当前激活的菜单索引
const activeContent = ref<any | null>(null); // 当前显示的内容
const contentArea = ref<HTMLElement | null>(null); // 用于引用 content-area

// 选择菜单项
const selectMenu = (index: number) => {
    activeIndex.value = index;
    activeContent.value = contents[index];
};

// 全局点击事件处理
const handleClickOutside = (event: MouseEvent) => {
    if (contentArea.value && !contentArea.value.contains(event.target as Node)) {
        hideContent(); // 只有点击不在 content-area 内部时，才隐藏内容
    }
};

// 生命周期钩子：组件挂载和卸载时管理全局事件
onMounted(() => {
    window.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
    window.removeEventListener('click', handleClickOutside);
});


// 隐藏内容区域
const hideContent = () => {
    activeIndex.value = null;
    activeContent.value = null;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "sidebar",
      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
    }, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(menuItems, (item, index) => {
        return _createElementVNode("div", {
          key: index,
          class: _normalizeClass(['menu-item', { active: activeIndex.value === index }]),
          onClick: ($event: any) => (selectMenu(index))
        }, [
          _createElementVNode("i", {
            class: _normalizeClass([item.icon, "menu-icon"])
          }, null, 2),
          _createElementVNode("div", null, _toDisplayString(item.label), 1)
        ], 10, _hoisted_2)
      }), 64))
    ]),
    (activeContent.value !== null)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "content-area",
          ref_key: "contentArea",
          ref: contentArea,
          onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
        }, [
          _createElementVNode("h2", null, _toDisplayString(activeContent.value.title), 1),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activeContent.value.characters, (character, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "grid-item",
                key: index
              }, [
                _createElementVNode("img", {
                  src: character.image,
                  class: "character-image"
                }, null, 8, _hoisted_4),
                _createElementVNode("div", null, _toDisplayString(character.name), 1)
              ]))
            }), 128))
          ])
        ], 512))
      : _createCommentVNode("", true)
  ]))
}
}

})