import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "copywriting-setting" }
const _hoisted_2 = { class: "top-navbar" }
const _hoisted_3 = { class: "content-area" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "video-options" }
const _hoisted_6 = { class: "radio-group" }
const _hoisted_7 = { class: "radio-options" }
const _hoisted_8 = { class: "radio-group" }
const _hoisted_9 = { class: "radio-options" }
const _hoisted_10 = { class: "radio-group" }
const _hoisted_11 = { class: "radio-options" }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 2 }

import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'; // 引入 Vue Router


export default /*@__PURE__*/_defineComponent({
  __name: 'PlanSetting',
  setup(__props) {

const router = useRouter(); // 获取 router 实例
const store = useStore();

// 使用 Vuex 的 getters 获取状态，并通过 set 方法更新 Vuex 中的状态
const activeView = computed({
  get: () => store.getters['VideoSettings/getActiveView'],
  set: (newView) => store.commit('VideoSettings/setActiveView', newView),
});

const aspectRatio = computed({
  get: () => store.getters['VideoSettings/getAspectRatio'],
  set: (newRatio) => store.commit('VideoSettings/setAspectRatio', newRatio),
});

const materialType = computed({
  get: () => store.getters['VideoSettings/getMaterialType'],
  set: (newType) => store.commit('VideoSettings/setMaterialType', newType),
});

const voiceTone = computed({
  get: () => store.getters['VideoSettings/getVoiceTone'],
  set: (newTone) => store.commit('VideoSettings/setVoiceTone', newTone),
});

const currentPage = computed(() => store.getters['ViewPlan/getCurrentPage']);

const currentPageContent = computed({
  get: () => store.getters['ViewPlan/getCurrentPageContent'],
  set: (newContent) => store.commit('ViewPlan/updatePageContent', { index: currentPage.value, content: newContent })
});

// 生成脚本
const generateStoryboard = async () => {
  router.push({ name: 'storyboard' }); // 跳转到 storyboard 页面
  // 调用 submitCopywriting 方法
  await store.dispatch('VideoSettings/submitCopywriting');
};



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("span", {
        class: _normalizeClass(["nav-link", { active: activeView.value === 'video settings' }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (activeView.value = 'video settings'))
      }, " 视频设置 ", 2),
      _createElementVNode("span", {
        class: _normalizeClass(["nav-link", { active: activeView.value === 'advanced setting' }]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (activeView.value = 'advanced setting'))
      }, " 高级设置 ", 2),
      _createElementVNode("span", {
        class: _normalizeClass(["nav-link", { active: activeView.value === 'more' }]),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (activeView.value = 'more'))
      }, " 更多 ", 2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (activeView.value === 'video settings')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _cache[24] || (_cache[24] = _createElementVNode("label", { class: "group-label" }, "视频比例：", -1)),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("label", null, [
                    _withDirectives(_createElementVNode("input", {
                      type: "radio",
                      value: "16:9",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((aspectRatio).value = $event))
                    }, null, 512), [
                      [_vModelRadio, aspectRatio.value]
                    ]),
                    _cache[19] || (_cache[19] = _createTextVNode(" 16:9"))
                  ]),
                  _createElementVNode("label", null, [
                    _withDirectives(_createElementVNode("input", {
                      type: "radio",
                      value: "9:16",
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((aspectRatio).value = $event))
                    }, null, 512), [
                      [_vModelRadio, aspectRatio.value]
                    ]),
                    _cache[20] || (_cache[20] = _createTextVNode(" 9:16"))
                  ]),
                  _createElementVNode("label", null, [
                    _withDirectives(_createElementVNode("input", {
                      type: "radio",
                      value: "4:3",
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((aspectRatio).value = $event))
                    }, null, 512), [
                      [_vModelRadio, aspectRatio.value]
                    ]),
                    _cache[21] || (_cache[21] = _createTextVNode(" 4:3"))
                  ]),
                  _createElementVNode("label", null, [
                    _withDirectives(_createElementVNode("input", {
                      type: "radio",
                      value: "3:4",
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((aspectRatio).value = $event))
                    }, null, 512), [
                      [_vModelRadio, aspectRatio.value]
                    ]),
                    _cache[22] || (_cache[22] = _createTextVNode(" 3:4"))
                  ]),
                  _createElementVNode("label", null, [
                    _withDirectives(_createElementVNode("input", {
                      type: "radio",
                      value: "1:1",
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((aspectRatio).value = $event))
                    }, null, 512), [
                      [_vModelRadio, aspectRatio.value]
                    ]),
                    _cache[23] || (_cache[23] = _createTextVNode(" 1:1"))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _cache[27] || (_cache[27] = _createElementVNode("label", { class: "group-label" }, "素材类型：", -1)),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("label", null, [
                    _withDirectives(_createElementVNode("input", {
                      type: "radio",
                      value: "在线素材",
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((materialType).value = $event))
                    }, null, 512), [
                      [_vModelRadio, materialType.value]
                    ]),
                    _cache[25] || (_cache[25] = _createTextVNode(" 在线素材"))
                  ]),
                  _createElementVNode("label", null, [
                    _withDirectives(_createElementVNode("input", {
                      type: "radio",
                      value: "AI生成素材",
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((materialType).value = $event))
                    }, null, 512), [
                      [_vModelRadio, materialType.value]
                    ]),
                    _cache[26] || (_cache[26] = _createTextVNode(" AI生成素材"))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _cache[37] || (_cache[37] = _createElementVNode("label", { class: "group-label" }, "旁白音调：", -1)),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("label", null, [
                    _withDirectives(_createElementVNode("input", {
                      type: "radio",
                      value: "男-宣传片",
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((voiceTone).value = $event))
                    }, null, 512), [
                      [_vModelRadio, voiceTone.value]
                    ]),
                    _cache[28] || (_cache[28] = _createTextVNode(" 男-宣传片"))
                  ]),
                  _createElementVNode("label", null, [
                    _withDirectives(_createElementVNode("input", {
                      type: "radio",
                      value: "女-温柔知性",
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((voiceTone).value = $event))
                    }, null, 512), [
                      [_vModelRadio, voiceTone.value]
                    ]),
                    _cache[29] || (_cache[29] = _createTextVNode(" 女-温柔知性"))
                  ]),
                  _createElementVNode("label", null, [
                    _withDirectives(_createElementVNode("input", {
                      type: "radio",
                      value: "男-叙事",
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((voiceTone).value = $event))
                    }, null, 512), [
                      [_vModelRadio, voiceTone.value]
                    ]),
                    _cache[30] || (_cache[30] = _createTextVNode(" 男-叙事"))
                  ]),
                  _createElementVNode("label", null, [
                    _withDirectives(_createElementVNode("input", {
                      type: "radio",
                      value: "男-新闻广播",
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((voiceTone).value = $event))
                    }, null, 512), [
                      [_vModelRadio, voiceTone.value]
                    ]),
                    _cache[31] || (_cache[31] = _createTextVNode(" 男-新闻广播"))
                  ]),
                  _createElementVNode("label", null, [
                    _withDirectives(_createElementVNode("input", {
                      type: "radio",
                      value: "男-科技",
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((voiceTone).value = $event))
                    }, null, 512), [
                      [_vModelRadio, voiceTone.value]
                    ]),
                    _cache[32] || (_cache[32] = _createTextVNode(" 男-科技"))
                  ]),
                  _createElementVNode("label", null, [
                    _withDirectives(_createElementVNode("input", {
                      type: "radio",
                      value: "男-讲故事2",
                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((voiceTone).value = $event))
                    }, null, 512), [
                      [_vModelRadio, voiceTone.value]
                    ]),
                    _cache[33] || (_cache[33] = _createTextVNode(" 男-讲故事2"))
                  ]),
                  _createElementVNode("label", null, [
                    _withDirectives(_createElementVNode("input", {
                      type: "radio",
                      value: "男-讲故事",
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((voiceTone).value = $event))
                    }, null, 512), [
                      [_vModelRadio, voiceTone.value]
                    ]),
                    _cache[34] || (_cache[34] = _createTextVNode(" 男-讲故事"))
                  ]),
                  _createElementVNode("label", null, [
                    _withDirectives(_createElementVNode("input", {
                      type: "radio",
                      value: "男-房地产",
                      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((voiceTone).value = $event))
                    }, null, 512), [
                      [_vModelRadio, voiceTone.value]
                    ]),
                    _cache[35] || (_cache[35] = _createTextVNode(" 男-房地产"))
                  ]),
                  _createElementVNode("label", null, [
                    _withDirectives(_createElementVNode("input", {
                      type: "radio",
                      value: "男-MG动画",
                      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((voiceTone).value = $event))
                    }, null, 512), [
                      [_vModelRadio, voiceTone.value]
                    ]),
                    _cache[36] || (_cache[36] = _createTextVNode(" 男-MG动画"))
                  ])
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (activeView.value === 'advanced setting')
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[38] || (_cache[38] = [
            _createElementVNode("p", null, "这是是高级设置的内容", -1)
          ])))
        : _createCommentVNode("", true),
      (activeView.value === 'more')
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, _cache[39] || (_cache[39] = [
            _createElementVNode("p", null, "这里是更多内容。", -1)
          ])))
        : _createCommentVNode("", true),
      _createElementVNode("div", { class: "send-button" }, [
        _createElementVNode("button", {
          class: "jumpButton",
          onClick: generateStoryboard
        }, "分镜生成")
      ])
    ])
  ]))
}
}

})