<template>
  <div class="custom-video">
    <!-- 根据状态条件渲染 VideoCard 或相应的表单 -->
    <div v-if="!isCorporateViewVisible" class="video-type">
      <VideoCardYComponents 
        v-for="(card, index) in videoCards" 
        :key="index" 
        :icon="card.icon" 
        :title="card.title"
        @click="showCorporateView(card)" 
      />
    </div>

    <!-- <div v-else class="vue-form">
      <component :is="currentFormComponent" @goBack="goBack" :selectedTitle="selectedTitle" />
    </div> -->

    
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import VideoCardYComponents from '@/components/VideoCardY.vue';

// 状态管理：从 Vuex 获取状态
const store = useStore();
const isCorporateViewVisible = computed(() => store.getters['ViewPlan/getIsCorporateViewVisible']);

// 视频卡片数据
const videoCards = [
  {
    id: '600001',
    type: 'broadcasting',
    icon: require('@/assets/classify_pic/ipCreation/电影解说.jpg'),
    title: '电影解说',
  },
  {
    id: '600002',
    type: 'broadcasting',
    icon: require('@/assets/classify_pic/ipCreation/萌宠可爱.jpg'),
    title: '萌宠可爱',
  },
  {
    id: '600003',
    type: 'broadcasting',
    icon: require('@/assets/classify_pic/ipCreation/情感鸡汤.jpg'),
    title: '情感鸡汤',
  },
  {
    id: '600004',
    type: 'broadcasting',
    icon: require('@/assets/classify_pic/ipCreation/生活科普.jpg'),
    title: '生活科普',
  },
  {
    id: '600005',
    type: 'broadcasting',
    icon: require('@/assets/classify_pic/ipCreation/颜值舞蹈.jpg'),
    title: '颜值舞蹈',
  }, 
  {
    id: '600006',
    type: 'broadcasting',
    icon: require('@/assets/classify_pic/ipCreation/知识分享.jpg'),
    title: '知识分享',
  }, 
  {
    id: '600007',
    type: 'broadcasting',
    icon: require('@/assets/classify_pic/ipCreation/历史解说.jpg'),
    title: '历史解说',
  }, 
] as const;


// 显示 CorporateVideoView 的函数
const showCorporateView = (card: typeof videoCards[number]) => {
  store.commit('ViewPlan/setSelectedTitle', card.title);
  store.commit('ViewPlan/setCurrentScriptType', card.type);
  store.commit('ViewPlan/setVideoData', card);
  store.commit('ViewPlan/setIsCorporateViewVisible', true);
  store.dispatch('ViewPlan/updateScriptType');
};

</script>

<style lang="scss" scoped>
.custom-video {
  flex-wrap: wrap;
  max-width: 100%;
  box-sizing: border-box;
  min-height: 540px;
  max-height: 540px !important;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
}

.video-type {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
}

/* Webkit 滚动条样式（适用于 Chrome、Safari 和 Edge） */
.custom-video::-webkit-scrollbar {
  width: 8px;
  /* 设置滚动条宽度 */
  height: 8px;
  /* 设置横向滚动条宽度 */
}

.custom-video::-webkit-scrollbar-track {
  background-color: #2b2e3d;
  /* 滚动条轨道背景 */
  border-radius: 10px;
  /* 轨道圆角 */
}

.custom-video::-webkit-scrollbar-thumb {
  background-color: #888;
  /* 滚动条的颜色 */
  border-radius: 10px;
  /* 滚动条的圆角 */
  border: 2px solid #2b2e3d;
  /* 滚动条的边框 */
}

.custom-video::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* 滚动条悬停时的颜色 */
}


</style>