import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "planMain" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "pagination" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "feedback" }

import { reactive, ref, computed, watch, nextTick } from 'vue';
import { useRouter } from 'vue-router'; // 引入 Vue Router
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'PlanMainView',
  setup(__props) {

const router = useRouter(); // 获取 router 实例
// 使用 Vuex store
const store = useStore(); 
const currentPage = computed(() => store.getters['ViewPlan/getCurrentPage']);
const totalPages = computed(() => store.getters['ViewPlan/getTotalPages']);
const currentPageContent = computed({
  get: () => store.getters['ViewPlan/getCurrentPageContent'],
  set: (newContent) => store.commit('ViewPlan/updatePageContent', { index: currentPage.value, content: newContent })
});
const currentPageInput = computed(() => store.getters['ViewPlan/getCurrentPageInput']);
const newFeedback = ref(''); // 初始化为当前页的输入内容

// 自动调整textarea高度
const autoResize = (event: Event) => {
  const textarea = event.target as HTMLTextAreaElement;
  // 重置高度，防止高度累积
  textarea.style.height = 'auto';
  // 动态设置textarea高度，使其适应内容
  textarea.style.height = `${textarea.scrollHeight}px`;
};


// 翻页功能
const prevPage = () => {
  if (currentPage.value > 0) {
    store.commit('ViewPlan/setCurrentPage', currentPage.value - 1);
  }
};

const nextPage = () => {
  if (currentPage.value < totalPages.value - 1) {
    store.commit('ViewPlan/setCurrentPage', currentPage.value + 1);
  }
};

// 保存修改意见
const sendFeedback = () => {
  store.dispatch('ViewPlan/insertModRes', { index: currentPage.value, input: newFeedback.value });
  // 清空 textarea 内容
  newFeedback.value = '';
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "page-title" }, "创意生成", -1)),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("textarea", {
        class: "content-box",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((currentPageContent).value = $event)),
        rows: "10",
        placeholder: "1、请在左侧选择您要制作的脚本类型。 2、填写一些脚本相关的必要信息。  3、填写完毕后，点击策划生成按钮。 4、稍等片刻。 5、在下方的修改意见输入您的意见。 6、修改到满意的版本后，点击文案生成按钮，进行下一步的文案生成。"
      }, null, 512), [
        [_vModelText, currentPageContent.value]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        onClick: prevPage,
        disabled: currentPage.value === 0
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("i", { class: "bi bi-arrow-left-circle-fill" }, null, -1)
      ]), 8, _hoisted_4),
      _createElementVNode("span", null, "   " + _toDisplayString(currentPage.value + 1) + " / " + _toDisplayString(totalPages.value) + "   ", 1),
      _createElementVNode("button", {
        onClick: nextPage,
        disabled: currentPage.value === totalPages.value - 1
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("i", { class: "bi bi-arrow-right-circle-fill" }, null, -1)
      ]), 8, _hoisted_5)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((newFeedback).value = $event)),
        class: "feedback-textarea",
        onInput: autoResize,
        placeholder: "输入您的修改意见"
      }, null, 544), [
        [_vModelText, newFeedback.value]
      ]),
      _createElementVNode("button", {
        class: "sendButton",
        onClick: sendFeedback
      }, "发送")
    ])
  ]))
}
}

})