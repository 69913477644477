import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vModelText as _vModelText, vModelSelect as _vModelSelect, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "storyboard-main" }
const _hoisted_2 = { class: "custom-table" }
const _hoisted_3 = ["onClick", "onDrop"]
const _hoisted_4 = { class: "actions" }
const _hoisted_5 = { class: "action-container" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onDragstart", "onDrop"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "image-container" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  key: 0,
  class: "overlay"
}
const _hoisted_12 = { class: "button-container" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["onChange"]
const _hoisted_15 = { class: "re-img" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "content" }
const _hoisted_18 = ["onUpdate:modelValue"]
const _hoisted_19 = { class: "content" }
const _hoisted_20 = ["onUpdate:modelValue"]
const _hoisted_21 = { class: "camera-lens" }
const _hoisted_22 = ["onUpdate:modelValue"]
const _hoisted_23 = ["value"]
const _hoisted_24 = ["onUpdate:modelValue"]
const _hoisted_25 = ["value"]
const _hoisted_26 = ["onUpdate:modelValue"]
const _hoisted_27 = ["value"]
const _hoisted_28 = { class: "elements" }
const _hoisted_29 = ["onClick"]
const _hoisted_30 = ["onClick"]
const _hoisted_31 = { key: 0 }
const _hoisted_32 = { key: 1 }
const _hoisted_33 = {
  key: 0,
  class: "custom-dropdown"
}
const _hoisted_34 = { class: "checkbox-group" }
const _hoisted_35 = ["value", "onUpdate:modelValue", "onChange"]
const _hoisted_36 = ["onUpdate:modelValue"]
const _hoisted_37 = ["value"]
const _hoisted_38 = ["onUpdate:modelValue"]
const _hoisted_39 = { class: "style" }
const _hoisted_40 = ["onUpdate:modelValue"]
const _hoisted_41 = ["value"]
const _hoisted_42 = { class: "prompt" }
const _hoisted_43 = ["onUpdate:modelValue"]
const _hoisted_44 = { class: "prompt" }
const _hoisted_45 = ["onUpdate:modelValue"]
const _hoisted_46 = ["src"]

import { ref, watch, nextTick, onMounted, onBeforeUnmount, computed, Ref } from 'vue';
import { useRouter } from 'vue-router'; // 引入 useRouter
import { useStore } from 'vuex';
import type { TableRow } from '@/store/modules/ViewStoryboard';
import { setRootScrollTop } from 'vant/lib/utils';



export default /*@__PURE__*/_defineComponent({
  __name: 'StoryboardMain',
  setup(__props) {

const router = useRouter(); // 初始化 router 实例
const selectedRow = ref(0); // 默认选中第一行
const isImageModalOpen = ref(false); // 控制模态框的显示状态
const currentImage = ref(''); // 存储当前点击的图片 URL
const draggedRowIndex = ref<number | null>(null); // 当前被拖拽的行索引


// 初始化 store
const store = useStore();
// 通过 computed 获取 Vuex 中的 tableData
const tableData = computed(() => store.getters['ViewStoryboard/getTableData'] || []);


// 用来存储文件输入框的动态引用
const fileInputRefs = ref<{ [key: number]: HTMLInputElement | null }>({});

// 触发文件输入框
const triggerFileInput = (index: number) => {
    // 通过 fileInputRefs 获取对应索引的文件输入框
    const fileInput = fileInputRefs.value[index];
    if (fileInput) {
        (fileInput as HTMLInputElement).click();  // 类型断言为 HTMLInputElement
    }
};

// 处理文件上传
const handleFileChange = async (index: number, event: Event) => {
  const input = event.target as HTMLInputElement;
  const file = input.files?.[0];

  if (file) {
    // Blob 是文件本身的二进制表示
    const blob = new Blob([file], { type: file.type });

    // 打印 Blob 信息
    console.log('转换后的 Blob:', blob);

    // 如果需要使用 Blob 生成 URL（例如显示在 img 标签中）
    const imageUrl = URL.createObjectURL(blob);
    console.log('Blob 转换成的 URL:', imageUrl);

    // 预览图片
    tableData.value[index].image = imageUrl;
  }
};

// 为了避免同时点击多次，使用 row 的 isGenerating 状态
const regenerateImage = async (row: any): Promise<void> => {
    const id = row.id;

    // 如果图片正在生成，直接返回
    if (row.isGenerating) {
        console.log('图片正在生成，请稍等...');
        return;
    }

    // 设置 isGenerating 为 true，表示正在生成图片
    store.commit('ViewStoryboard/setIsGenerating', { id, isGenerating: true });

    try {
        // 等待图片生成完成
        await store.dispatch('ViewStoryboard/generateImageForRowAction', id);
        console.log(`图片 ${id} 生成完成`);
    } catch (error) {
        console.error(`生成图片失败，行 ID: ${id}`, error);
    } finally {
        // 生成完成后，无论成功还是失败，都将 isGenerating 设置为 false
        store.commit('ViewStoryboard/setIsGenerating', { id, isGenerating: false });
    }
};




// 当前显示下拉框的行索引，默认不显示
const currentDropdownIndex = ref<number | null>(null);

// 可自定义的角色列表，不与 tableData 耦合
const availableCharacters = ref<string[]>(['嫦娥', '后羿', '玉兔', '吴刚', '天蓬']);

// 保存每一行的已选择角色，使用 string 作为键
const selectedCharactersByRow = ref<Record<string, string[]>>({});

// 点击选中框时，显示/隐藏选择列表
const toggleDropdown = (index: number) => {
    if (currentDropdownIndex.value === index) {
        currentDropdownIndex.value = null; // 如果点击的是当前的下拉框，则关闭
    } else {
        currentDropdownIndex.value = index; // 否则打开对应的下拉框
    }
};

// 更新每行角色选择后的回调，将角色数组转化为空格分隔的字符串
const updateCharacter = (row: any) => {
    let selectedCharacters = selectedCharactersByRow.value[row.id];

    // 确保 selectedCharacters 是一个数组，如果不是则初始化为空数组
    if (!Array.isArray(selectedCharacters)) {
        selectedCharacters = [];
        selectedCharactersByRow.value[row.id] = selectedCharacters; // 初始化为空数组
    }

    // 将选中的角色通过空格分隔连接成字符串并存储到 row.character
    row.character = selectedCharacters.join(' ');

    // 同步更新 Vuex 模块中的数据
    store.dispatch('ViewStoryboard/updateRowAction', { id: row.id, updatedRow: { character: row.character } });
};

// 点击页面其他地方时关闭选择框
const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (!target.closest('.character')) {
        currentDropdownIndex.value = null; // 点击外部时，关闭所有下拉框
    }
};

// 定义每个 select 的选项列表
const styleOptions = ref(["插画风", "写实风", "商务风", "现代都市风"]);
const sceneOptions = ref(["梦幻场景", "现实场景"]);
const motionOptions = ref(["前推镜头", "后拉镜头", "摇镜头", "平移镜头", "跟拍镜头", "甩镜头", "上升镜头", "下降镜头", "环绕镜头", "穿越镜头"]);
const angleOptions = ref(["俯拍", "仰拍", "侧面（45°）", "正侧面", "背面"]);
const shotSizeOptions = ref(["大远景", "远景", "全景", "中景", "近景", "特写", "大特写"]);

// 通用方法：检查初始值是否在选项列表中，不在则添加
function checkAndAddOption(value: string, options: Ref<string[]>) {
    if (value && !options.value.includes(value)) {
        options.value.push(value);
    }
}


// 添加新行
const addRow = (index: number) => {
    console.log('Add row clicked at index:', index); // 调试日志，确认点击的行索引

    // 深拷贝行数据，确保所有字段都被复制
    const newRow = JSON.parse(JSON.stringify(tableData.value[index]));
    newRow.id = Date.now().toString(); // 为新行生成唯一 ID

    // 调用模块中的 addRow 方法，并传递插入位置
    store.dispatch('ViewStoryboard/addRowAction', { newRow, insertIndex: index + 1 });

    // 更新行号或序列
    updateShotNumbers();
};

// 删除行
const deleteRow = (index: number) => {
    if (tableData.value.length === 1) {
        console.log('无法删除最后一行');
        return; // 不允许删除最后一行
    }
    tableData.value.splice(index, 1); // 从表格中删除行
    updateShotNumbers(); // 重新更新所有行的index
    console.log(tableData.value)
};

// 更新数据
const updateRowData = (id: string, updatedData: Partial<typeof tableData.value[0]>) => {
    store.dispatch('ViewStoryboard/updateRowAction', { id, updatedRow: updatedData }); // 更新模块中的数据
};

// 更新镜头号
const updateShotNumbers = () => {
    tableData.value.forEach((row: TableRow, index: number) => {
        const updatedIndex = index + 1; // 重新编号，从 1 开始
        if (row.index !== updatedIndex) {
            row.index = updatedIndex; // 更新本地数据
            updateRowData(row.id, { index: updatedIndex }); // 同步更新到模块中的数据
        }
    });
};

// 打开图片模态框
const openImageModal = (imageUrl: string) => {
    currentImage.value = imageUrl;
    isImageModalOpen.value = true;
};

// 关闭图片模态框
const closeImageModal = () => {
    isImageModalOpen.value = false;
};

// 自动调整 textarea 高度
const autoResize = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = 'auto'; // 重置高度，避免高度累积
    textarea.style.height = `${textarea.scrollHeight}px`; // 根据内容设置高度
};

// 自动调整所有 textarea 高度
const autoResizeAll = () => {
    const textareas = document.querySelectorAll('textarea');
    textareas.forEach((textarea) => autoResize(textarea as HTMLTextAreaElement));
};

// // 自动调整 input 宽度
// const adjustInputWidth = (input: HTMLInputElement) => {
//     input.style.width = `${input.value.length || 1}ch`; // 根据字符数动态调整宽度
// };

// 为 textarea 和 input 添加事件监听
const addListeners = () => {
    document.querySelectorAll('textarea').forEach((textarea) => {
        textarea.addEventListener('input', (event) => autoResize(event.target as HTMLTextAreaElement));
    });

    // document.querySelectorAll('input[type="text"]').forEach((input) => {
    //     input.addEventListener('input', (event) => adjustInputWidth(event.target as HTMLInputElement));
    // });
};

// 选择行
const selectRow = (index: number) => {
    selectedRow.value = index;
};

// 拖拽开始，只在按钮上触发拖拽逻辑，并手动设置拖拽数据
const onDragStart = (index: number, event: DragEvent) => {
    draggedRowIndex.value = index;

    // 将 event.target 断言为 HTMLElement，确保可以调用 closest
    const draggedRowElement = (event.target as HTMLElement)?.closest('tr') as HTMLElement;
    if (draggedRowElement) {
        event.dataTransfer?.setData('text/html', draggedRowElement.outerHTML);
        event.dataTransfer?.setDragImage(draggedRowElement, 0, 0); // 让拖拽时显示的图像为整行
    }
};

// 放置拖拽行
const onDrop = (index: number) => {
    if (draggedRowIndex.value !== null && draggedRowIndex.value !== index) {
        const draggedRow = tableData.value.splice(draggedRowIndex.value, 1)[0];
        tableData.value.splice(index, 0, draggedRow);
        updateRowData; // 更新数据
        selectRow(index); // 选中新行
        updateShotNumbers();
        console.log(tableData.value)
    }
    draggedRowIndex.value = null;
};


// 下载表格
const downoadFrom = () => {
    alert("下载表格功能未开发");
};

// 在组件挂载时自动执行调整
onMounted(async () => {
    tableData.value.forEach((row: TableRow) => {
        checkAndAddOption(row.style, styleOptions);
        checkAndAddOption(row.scene, sceneOptions);
        checkAndAddOption(row.camera_motion, motionOptions);
        checkAndAddOption(row.camera_angle, angleOptions);
        checkAndAddOption(row.shot_size, shotSizeOptions);
    });
    // try {
    //     // 在组件挂载时自动调用 checkAndGenerateImage 方法
    //     await store.dispatch('ViewStoryboard/checkAndGenerateImage');
    // } catch (error) {
    //     console.error('在挂载时生成图片出错', error);
    // }

    document.addEventListener('click', handleClickOutside);
    nextTick(() => {
        autoResizeAll();  // 页面加载或返回时，调整所有 textarea 高度
        addListeners();   // 添加事件监听，确保新的 textarea 和 input 可以自动调整
    });
});


// 监听 tableData 的变化，确保新添加的行的默认值也能加入选项列表中
watch(tableData, (newTableData) => {
    newTableData.forEach((row: TableRow) => {
        checkAndAddOption(row.style, styleOptions);
        checkAndAddOption(row.scene, sceneOptions);
        checkAndAddOption(row.camera_motion, motionOptions);
        checkAndAddOption(row.camera_angle, angleOptions);
        checkAndAddOption(row.shot_size, shotSizeOptions);
    });
}, { deep: true });


onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside);
});

// 监听 tableData 的变化，并初始化 selectedCharactersByRow
watch(
    tableData,
    (newVal) => {
        newVal.forEach((row: TableRow) => {
            if (typeof row.character === 'string' && row.character.length > 0) {
                selectedCharactersByRow.value[row.id] = row.character.split(' ');
            }
        });
    },
    { immediate: true, deep: true }
);

// 监听 tableData 中 content 的变化
watch(
    () => tableData.value?.map((row: TableRow) => row.content) ?? [], // 若 tableData.value 为 undefined 则返回空数组
    () => {
        nextTick(() => {
            autoResizeAll(); // 调整所有 textarea 的高度
            addListeners();  // 添加事件监听
        });
    },
    { deep: true }
);

// 跳转到 video-editing 路由的方法
const navigateToVideoEditing = async () => {
    router.push({ path: '/video-editing' }); // 页面跳转
    await store.dispatch('ViewStoryboard/generateVoiceoverAction')
    await store.dispatch('ViewEdit/initVideoSegments');
};



return (_ctx: any,_cache: any) => {
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[11] || (_cache[11] = _createElementVNode("h1", { class: "table-title" }, "《此处为标题》", -1)),
    _createElementVNode("table", _hoisted_2, [
      _cache[10] || (_cache[10] = _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", null, "操作"),
          _createElementVNode("th", null, "镜头号"),
          _createElementVNode("th", null, "效果图"),
          _createElementVNode("th", null, "画面内容"),
          _createElementVNode("th", null, "对应旁白"),
          _createElementVNode("th", null, "镜头"),
          _createElementVNode("th", null, "元素"),
          _createElementVNode("th", null, "秒数"),
          _createElementVNode("th", null, "风格"),
          _createElementVNode("th", null, "图像提示词"),
          _createElementVNode("th", null, "视频提示词")
        ])
      ], -1)),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tableData.value, (row, index) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: row.id,
            class: _normalizeClass({ selected: selectedRow.value === index }),
            onClick: ($event: any) => (selectRow(index)),
            onDragover: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
            onDrop: ($event: any) => (onDrop(index))
          }, [
            _createElementVNode("td", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("button", {
                  class: "add-button",
                  onClick: ($event: any) => (deleteRow(index))
                }, "-", 8, _hoisted_6),
                _createElementVNode("button", {
                  class: "drag-button",
                  draggable: "true",
                  onDragstart: ($event: any) => (onDragStart(index, $event)),
                  onDragover: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
                  onDrop: ($event: any) => (onDrop(index))
                }, "☰", 40, _hoisted_7),
                _createElementVNode("button", {
                  class: "sub-button",
                  onClick: ($event: any) => (addRow(index))
                }, "+", 8, _hoisted_8)
              ])
            ]),
            _createElementVNode("td", null, _toDisplayString(index + 1), 1),
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_9, [
                _withDirectives(_createElementVNode("img", {
                  alt: "效果图",
                  class: "image-preview",
                  onClick: ($event: any) => (openImageModal(row.image)),
                  crossorigin: "anonymous"
                }, null, 8, _hoisted_10), [
                  [_directive_lazy, row.image]
                ]),
                (row.isGenerating)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[2] || (_cache[2] = [
                      _createElementVNode("span", { class: "loading-text" }, "正在生成中...", -1)
                    ])))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", {
                  class: "upload-img",
                  onClick: ($event: any) => (triggerFileInput(index))
                }, [
                  _cache[3] || (_cache[3] = _createElementVNode("i", { class: "bi bi-box-arrow-up" }, null, -1)),
                  _createElementVNode("input", {
                    type: "file",
                    accept: "image/*",
                    style: {"display":"none"},
                    onChange: ($event: any) => (handleFileChange(index, $event)),
                    ref_for: true,
                    ref: (el) => { if (el) fileInputRefs.value[index] = el as HTMLInputElement; }
                  }, null, 40, _hoisted_14)
                ], 8, _hoisted_13),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("i", {
                    class: "bi bi-arrow-clockwise",
                    onClick: ($event: any) => (regenerateImage(row))
                  }, null, 8, _hoisted_16)
                ])
              ])
            ]),
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_17, [
                _withDirectives(_createElementVNode("textarea", {
                  "onUpdate:modelValue": ($event: any) => ((row.content) = $event),
                  class: "textarea-box",
                  rows: "1"
                }, null, 8, _hoisted_18), [
                  [_vModelText, row.content]
                ])
              ])
            ]),
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_19, [
                _withDirectives(_createElementVNode("textarea", {
                  "onUpdate:modelValue": ($event: any) => ((row.voiceover) = $event),
                  class: "textarea-box",
                  rows: "1"
                }, null, 8, _hoisted_20), [
                  [_vModelText, row.voiceover]
                ])
              ])
            ]),
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", null, [
                  _cache[4] || (_cache[4] = _createElementVNode("label", null, "景别：", -1)),
                  _withDirectives(_createElementVNode("select", {
                    "onUpdate:modelValue": ($event: any) => ((row.shot_size) = $event),
                    class: "select-box"
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(shotSizeOptions.value, (option) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: option,
                        value: option
                      }, _toDisplayString(option), 9, _hoisted_23))
                    }), 128))
                  ], 8, _hoisted_22), [
                    [_vModelSelect, row.shot_size]
                  ])
                ]),
                _createElementVNode("div", null, [
                  _cache[5] || (_cache[5] = _createElementVNode("label", null, "角度：", -1)),
                  _withDirectives(_createElementVNode("select", {
                    "onUpdate:modelValue": ($event: any) => ((row.camera_angle) = $event),
                    class: "select-box"
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(angleOptions.value, (option) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: option,
                        value: option
                      }, _toDisplayString(option), 9, _hoisted_25))
                    }), 128))
                  ], 8, _hoisted_24), [
                    [_vModelSelect, row.camera_angle]
                  ])
                ]),
                _createElementVNode("div", null, [
                  _cache[6] || (_cache[6] = _createElementVNode("label", null, "运镜：", -1)),
                  _withDirectives(_createElementVNode("select", {
                    "onUpdate:modelValue": ($event: any) => ((row.camera_motion) = $event),
                    class: "select-box"
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(motionOptions.value, (option) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: option,
                        value: option
                      }, _toDisplayString(option), 9, _hoisted_27))
                    }), 128))
                  ], 8, _hoisted_26), [
                    [_vModelSelect, row.camera_motion]
                  ])
                ])
              ])
            ]),
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("div", {
                  class: "character",
                  onClick: _withModifiers(($event: any) => (toggleDropdown(index)), ["self"])
                }, [
                  _createElementVNode("div", {
                    class: "selected-characters-box",
                    onClick: ($event: any) => (toggleDropdown(index))
                  }, [
                    _cache[7] || (_cache[7] = _createElementVNode("label", null, "角色：", -1)),
                    (selectedCharactersByRow.value[row.id] && selectedCharactersByRow.value[row.id].length)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_31, _toDisplayString(selectedCharactersByRow.value[row.id].join(' ')), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_32, "没有角色"))
                  ], 8, _hoisted_30),
                  (currentDropdownIndex.value === index)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                        _createElementVNode("div", _hoisted_34, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(availableCharacters.value, (character, idx) => {
                            return (_openBlock(), _createElementBlock("label", {
                              key: character,
                              class: "checkbox-label"
                            }, [
                              _withDirectives(_createElementVNode("input", {
                                type: "checkbox",
                                value: character,
                                "onUpdate:modelValue": ($event: any) => ((selectedCharactersByRow.value[row.id]) = $event),
                                onChange: ($event: any) => (updateCharacter(row))
                              }, null, 40, _hoisted_35), [
                                [_vModelCheckbox, selectedCharactersByRow.value[row.id]]
                              ]),
                              _cache[8] || (_cache[8] = _createElementVNode("span", { class: "checkbox-custom" }, null, -1)),
                              _createTextVNode(" " + _toDisplayString(character), 1)
                            ]))
                          }), 128))
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ], 8, _hoisted_29),
                _cache[9] || (_cache[9] = _createElementVNode("label", null, "场景：", -1)),
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": ($event: any) => ((row.scene) = $event),
                  class: "select-box"
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sceneOptions.value, (option) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: option,
                      value: option
                    }, _toDisplayString(option), 9, _hoisted_37))
                  }), 128))
                ], 8, _hoisted_36), [
                  [_vModelSelect, row.scene]
                ])
              ])
            ]),
            _createElementVNode("td", null, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": ($event: any) => ((row.duration) = $event),
                class: "input-box"
              }, null, 8, _hoisted_38), [
                [_vModelText, row.duration]
              ])
            ]),
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_39, [
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": ($event: any) => ((row.style) = $event),
                  class: "select-box"
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(styleOptions.value, (option) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: option,
                      value: option
                    }, _toDisplayString(option), 9, _hoisted_41))
                  }), 128))
                ], 8, _hoisted_40), [
                  [_vModelSelect, row.style]
                ])
              ])
            ]),
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_42, [
                _withDirectives(_createElementVNode("textarea", {
                  "onUpdate:modelValue": ($event: any) => ((row.image_prompt) = $event),
                  class: "textarea-box",
                  rows: "1"
                }, null, 8, _hoisted_43), [
                  [_vModelText, row.image_prompt]
                ])
              ])
            ]),
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_44, [
                _withDirectives(_createElementVNode("textarea", {
                  "onUpdate:modelValue": ($event: any) => ((row.video_prompt) = $event),
                  class: "textarea-box",
                  rows: "1"
                }, null, 8, _hoisted_45), [
                  [_vModelText, row.video_prompt]
                ])
              ])
            ])
          ], 42, _hoisted_3))
        }), 128))
      ])
    ]),
    _createElementVNode("div", { class: "table-actions" }, [
      _createElementVNode("button", {
        class: "btn download",
        onClick: downoadFrom
      }, "下载表格"),
      _createElementVNode("button", {
        class: "btn generate",
        onClick: navigateToVideoEditing
      }, "生成剪辑")
    ]),
    (isImageModalOpen.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "image-modal",
          onClick: closeImageModal
        }, [
          _createElementVNode("img", {
            src: currentImage.value,
            alt: "放大效果图",
            class: "modal-image",
            crossorigin: "anonymous"
          }, null, 8, _hoisted_46)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})