import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "view-type-selection" }
const _hoisted_2 = {
  key: 0,
  class: "navbar"
}
const _hoisted_3 = { class: "top-navbar" }
const _hoisted_4 = { class: "content-area" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { key: 3 }
const _hoisted_9 = { key: 4 }
const _hoisted_10 = { key: 5 }
const _hoisted_11 = {
  key: 1,
  class: "vue-form"
}

import { ref, computed } from 'vue';
import AdType from './video_type/AdType.vue';
import TrailerType from './video_type/TrailerType.vue';
import CartoonType from './video_type/CartoonType.vue';
import shotPlayType from './video_type/shotPlayType.vue';
import shotVideo from './video_type/shotVideo.vue';
import ipCreation from './video_type/ipCreation.vue';
import { useStore } from 'vuex';

import trailerVideo from '@/views/workbench/Plan/video_form/trailerVideo.vue';
import advertisingVideo from '@/views/workbench/Plan/video_form/advertisingVideo.vue';
import broadcastingVideo from '@/views/workbench/Plan/video_form/broadcastingVideo.vue';

// 创建一个状态变量，用来存储当前选中的视图

export default /*@__PURE__*/_defineComponent({
  __name: 'VideoTypeSelection',
  setup(__props) {

const activeView = ref('ad');
const store = useStore();

const isCorporateViewVisible = computed(() => store.getters['ViewPlan/getIsCorporateViewVisible']);

// 从 Vuex 中获取 selectedType，并断言类型为 keyof formComponentsMap
const selectedType = computed<keyof typeof formComponentsMap>(() => store.getters['ViewPlan/getScriptType']);
const selectedTitle = computed(() => store.getters['ViewPlan/getSelectedTitle']);

// 组件映射
const formComponentsMap = {
  trailer: trailerVideo,
  advertising: advertisingVideo,
  broadcasting: broadcastingVideo,
};


// 当前的表单组件
const currentFormComponent = computed(() => formComponentsMap[selectedType.value] || null);


// 返回上一步，显示导航栏和视频卡片
const goBack = () => {
  store.commit('ViewPlan/setIsCorporateViewVisible', false);
  store.commit('ViewPlan/setSelectedTitle', '');
  store.commit('ViewPlan/setVideoData', null);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!isCorporateViewVisible.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("nav", _hoisted_3, [
            _createElementVNode("span", {
              class: _normalizeClass(["nav-link", { active: activeView.value === 'ad' }]),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (activeView.value = 'ad'))
            }, " 广告 ", 2),
            _createElementVNode("span", {
              class: _normalizeClass(["nav-link", { active: activeView.value === 'promotion' }]),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (activeView.value = 'promotion'))
            }, " 宣传 ", 2),
            _createElementVNode("span", {
              class: _normalizeClass(["nav-link", { active: activeView.value === 'animation' }]),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (activeView.value = 'animation'))
            }, " 动画 ", 2),
            _createElementVNode("span", {
              class: _normalizeClass(["nav-link", { active: activeView.value === 'short_story' }]),
              onClick: _cache[3] || (_cache[3] = ($event: any) => (activeView.value = 'short_story'))
            }, " 短剧 ", 2),
            _createElementVNode("span", {
              class: _normalizeClass(["nav-link", { active: activeView.value === 'trailer' }]),
              onClick: _cache[4] || (_cache[4] = ($event: any) => (activeView.value = 'trailer'))
            }, " 短视频 ", 2),
            _createElementVNode("span", {
              class: _normalizeClass(["nav-link", { active: activeView.value === 'ip_creation' }]),
              onClick: _cache[5] || (_cache[5] = ($event: any) => (activeView.value = 'ip_creation'))
            }, " IP打造 ", 2)
          ]),
          _createElementVNode("div", _hoisted_4, [
            (activeView.value === 'ad')
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(AdType)
                ]))
              : _createCommentVNode("", true),
            (activeView.value === 'promotion')
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(TrailerType)
                ]))
              : _createCommentVNode("", true),
            (activeView.value === 'animation')
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(CartoonType)
                ]))
              : _createCommentVNode("", true),
            (activeView.value === 'short_story')
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(shotPlayType)
                ]))
              : _createCommentVNode("", true),
            (activeView.value === 'trailer')
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(shotVideo)
                ]))
              : _createCommentVNode("", true),
            (activeView.value === 'ip_creation')
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createVNode(ipCreation)
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_11, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(currentFormComponent.value), {
            onGoBack: goBack,
            selectedTitle: selectedTitle.value
          }, null, 40, ["selectedTitle"]))
        ]))
  ]))
}
}

})