import { Module, ActionContext } from 'vuex';
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg/dist/ffmpeg.min.js';
import { invokeVideoGeneration, queryVideoGeneration } from '@/api/edit';
import { get } from 'vant/lib/utils';


export interface VideoSegment {
    video_id: string;
    width: number;
    thumbnail: string;
    backgroundColor: string;
    name: string;
    videoUrl: string;
    video_state: string;
}


// 定义模块状态接口
export interface ViewEditState {
    player: HTMLVideoElement | null; // 视频播放器的引用，用于控制播放、暂停等操作
    canvas: HTMLCanvasElement | null; // 用于绘制视频帧的画布元素
    animationFrameId: number | null; // 保存 requestAnimationFrame 返回的动画帧 ID，便于停止帧绘制
    sources: string[]; // 存储视频片段的 Blob URL 数组，用于视频片段的合成
    preloadedVideos: string[]; // 预加载的视频片段 URL，用于快速访问和合成
    isPlaying: boolean; // 当前视频是否正在播放的状态，用于控制播放和暂停按钮的显示
    currentTime: number; // 当前视频的播放时间（秒），用于更新播放进度条
    timeMarks: { time: number; position: number; label: string }[]; // 时间刻度数组，用于显示在时间轴上的时间标记
    videoSegments: VideoSegment[];
    generatedAudioUrl: string; // 合成音频文件的 URL，用于合成视频时使用
    generatedAudioDuration: number; // 合成音频文件的时长（秒），用于计算和调整视频与音频的时长
    musicUrl: string; // 音乐文件的 URL，用于播放背景音乐
    musicDuration: number; // 音乐文件的时长（秒），用于显示和同步播放进度
    combinedVideoUrl: string | null; // 最终合成视频的 URL，用于播放合成后的完整视频
    selectedVideo: string; //当前被选中的视频片段ID
    isVideoSelected: boolean;  // 判断是否有选中的视频
    isShowDetails: boolean; //是否显示细节
}

// 初始化模块状态
const state: ViewEditState = {
    player: null, // 初始化播放器引用为空
    canvas: null, // 初始化画布引用为空
    animationFrameId: null, // 初始化动画帧 ID 为 null
    sources: [], // 初始化视频片段 URL 数组为空
    preloadedVideos: [], // 初始化预加载视频数组为空
    isPlaying: false, // 默认视频为暂停状态
    currentTime: 0, // 初始化当前播放时间为 0 秒
    timeMarks: [], // 初始化时间刻度数组为空
    videoSegments: [], // 初始化视频片段信息数组为空
    generatedAudioUrl: 'https://api.aizdc.com/static/audio/8a4d9b00-e8d6-405f-b9df-5738d0c9462a.mp3', // 设置默认的合成音频文件 URL
    generatedAudioDuration: 24.5, // 设置默认的合成音频时长为 24.5 秒
    musicUrl: 'https://api.aizdc.com/static/music/music1.MP3', // 设置默认的音乐文件 URL
    musicDuration: 144.5, // 设置默认的音乐时长为 144.5 秒
    combinedVideoUrl: null, // 初始化合成视频的 URL 为 null
    selectedVideo: "", //设置默认没有选中
    isVideoSelected: false, //设置默认没有被选中
    isShowDetails: false, //设置默认不显示细节
};


// 创建 FFmpeg 实例
const ffmpegInstance = createFFmpeg({
    log: false,
    wasmOptions: {
        initialMemory: 512 * 1024 * 1024,
        maximumMemory: 1024 * 1024 * 1024,
    },
});

// 定义 mutations：用于同步更新状态
const mutations = {

    // 更新视频片段信息
    updateVideoSegments(state: ViewEditState) {
        state.sources = state.videoSegments.map(segment => segment.videoUrl); // 提取 videoUrl
    },


    // 设置播放视频的url
    setCombinedVideoUrl(state: ViewEditState, url: string | null) {
        state.combinedVideoUrl = url;
    },

    // 更新视频片段的缩略图
    updateThumbnail(state: ViewEditState, { index, thumbnail }: { index: number; thumbnail: string }) {
        if (state.videoSegments[index]) {
            // console.log(`更新缩略图，索引 ${index}, URL:`, thumbnail); // 日志
            state.videoSegments[index].thumbnail = thumbnail;
        } else {
            console.error(`No segment found at index ${index}`);
        }
    },

    // 设置动画帧 ID
    setAnimationFrameId(state: ViewEditState, id: number | null) {
        state.animationFrameId = id;
    },

    // 更新播放器对象
    setPlayer(state: ViewEditState, player: HTMLVideoElement) {
        state.player = player;
    },
    // 更新画布对象
    setCanvas(state: ViewEditState, canvas: HTMLCanvasElement) {
        state.canvas = canvas;
    },
    // 设置视频片段的 Blob URL
    setSources(state: ViewEditState, sources: string[]) {
        state.sources = sources;
    },
    // 向 sources 添加新的视频 URL
    addSource(state: ViewEditState, source: string) {
        state.sources.push(source);
    },
    // 更新播放状态
    setPlayingState(state: ViewEditState, isPlaying: boolean) {
        state.isPlaying = isPlaying;
    },
    // 更新当前播放时间
    setCurrentTime(state: ViewEditState, currentTime: number) {
        state.currentTime = currentTime;
    },
    // 设置时间刻度
    setTimeMarks(state: ViewEditState, timeMarks: { time: number; position: number; label: string }[]) {
        state.timeMarks = timeMarks;
    },
    // 更新视频片段状态，包括宽度、缩略图、背景颜色和名称
    setVideoSegments(state: ViewEditState, videoSegments: { video_id: string, width: number; thumbnail: string; backgroundColor: string; name: string, videoUrl: string, video_state: string }[]) {
        state.videoSegments = videoSegments;
    },
    // 设置生成的音频 URL
    setGeneratedAudioUrl(state: ViewEditState, url: string) {
        state.generatedAudioUrl = url;
    },
    // 设置生成的音频时长
    setGeneratedAudioDuration(state: ViewEditState, duration: number) {
        state.generatedAudioDuration = duration;
    },

    // 设置选择的视频
    setSelectedVideo(state: ViewEditState, videoId: string) {
        state.selectedVideo = videoId;
        state.isVideoSelected = !!videoId;  // 有选中时设为 true，否则 false
    },

    // 清空选择的视频
    clearSelection(state: ViewEditState) {
        state.selectedVideo = "";
        state.isVideoSelected = false;
        state.isShowDetails = false;
    },

    setIsShowDetails(state: ViewEditState, isShowDetails: boolean) {
        state.isShowDetails = isShowDetails;
    },

    // 更新视频片段信息
    updateVideoSegmentById(state: ViewEditState, { video_id, updates }: { video_id: string; updates: Partial<VideoSegment> }) {
        const segment = state.videoSegments.find(seg => seg.video_id === video_id);
        if (segment) {
            Object.assign(segment, updates); // 使用 Object.assign 合并更新对象
        } else {
            console.warn(`Video segment with id ${video_id} not found.`);
        }
    },

    // 设置视频片段状态
    setVideoSegmentStatus(state: ViewEditState, { video_id, file_url, status }: { video_id: string; file_url: string; status: string }) {
        const segment = state.videoSegments.find(seg => seg.video_id === video_id);
        if (segment) {
            segment.videoUrl = file_url;
            segment.video_state = status;
        } else {
            console.warn(`Video segment with id ${video_id} not found.`);
        }
    },

};

// 定义 getters：用于获取状态数据
const getters = {

    isShowDetails(state: ViewEditState): boolean {
        return state.isShowDetails;
    },

    getSelectedVideo(state: ViewEditState): string {
        return state.selectedVideo;
    },

    isVideoSelected(state: ViewEditState): boolean {
        return state.isVideoSelected;
    },

    getPlayer(state: ViewEditState): HTMLVideoElement | null {
        return state.player;
    },
    getCanvas(state: ViewEditState): HTMLCanvasElement | null {
        return state.canvas;
    },
    getSources(state: ViewEditState): string[] {
        return state.sources;
    },
    isPlaying(state: ViewEditState): boolean {
        return state.isPlaying;
    },
    getCurrentTime(state: ViewEditState): number {
        return state.currentTime;
    },
    getTimeMarks(state: ViewEditState): { time: number; position: number; label: string }[] {
        return state.timeMarks;
    },
    getVideoSegments(state: ViewEditState): { video_id: string, width: number; thumbnail: string; backgroundColor: string; name: string }[] {
        return state.videoSegments;
    },
    getGeneratedAudioUrl(state: ViewEditState): string {
        return state.generatedAudioUrl;
    },
    getGeneratedAudioDuration(state: ViewEditState): number {
        return state.generatedAudioDuration;
    },
    getCombinedVideoUrl(state: ViewEditState): string | null {
        return state.combinedVideoUrl;
    },
    getVideoSegmentById: (state: ViewEditState) => (id: string) => {
        return state.videoSegments.find(segment => segment.video_id === id) || null;
    },
};

// 定义辅助函数来处理错误日志
const logError = (message: string, error: unknown) => console.error(`${message}:`, error);

// 定义 actions：用于执行异步操作和处理复杂逻辑
const actions = {
    // 确保 FFmpeg 实例加载完毕
    async loadFFmpeg() {
        try {
            if (!ffmpegInstance.isLoaded()) await ffmpegInstance.load();
        } catch (error) {
            logError("Failed to load FFmpeg", error);
        }
    },

    // 初始化视频片段状态
    async initVideoSegments(context: ActionContext<ViewEditState, any>) {

        console.log("开始初始化视频片段...");

        try {
            await context.dispatch('loadFFmpeg');
            console.log("FFmpeg 加载完成");

            const tableData = context.rootGetters['ViewStoryboard/getTableData'] || [];
            for (let i = 0; i < tableData.length; i++) {
                const row = tableData[i];
                if (!row.image || !row.duration) {
                    alert(`数据不完整: 第 ${i + 1} 行缺少 ${!row.image ? '图片' : ''}${!row.duration ? '时长' : ''}`);
                    console.log("数据不完整，初始化中止");
                    return;
                }
            }

            const totalVideoDuration = tableData.reduce((acc: number, item: { duration: string }) => acc + parseInt(item.duration), 0);
            const audioDuration = context.getters.getGeneratedAudioDuration;
            const maxDuration = Math.max(totalVideoDuration, audioDuration);

            console.log(`总视频时长: ${totalVideoDuration}s, 音频时长: ${audioDuration}s`);
            console.log(`最大时长: ${maxDuration}s`);

            context.commit('setSources', []);
            // 定义局部 videoSegments 数组
            const videoSegments: { video_id: string, width: number; thumbnail: string; backgroundColor: string; name: string, videoUrl: string, video_state: string }[] = [];

            for (let i = 0; i < tableData.length; i++) {
                const row = tableData[i];
                const outputName = `output${i + 1}.mp4`;

                console.log(`生成视频片段 ${outputName} 中...`);
                const videoUrl = await context.dispatch('createVideoFromImage', { imageFile: row.image, duration: parseInt(row.duration), outputName });
                context.commit('addSource', videoUrl);

                console.log(`生成的视频片段url "${outputName}":`, videoUrl);

                const segmentWidth = (parseInt(row.duration) / maxDuration) * 100;
                const backgroundColor = `hsl(${i * 100}, 50%, 50%)`;

                videoSegments.push({
                    video_id: tableData[i]["id"],
                    width: segmentWidth,
                    thumbnail: '',
                    backgroundColor,
                    name: row.image.split('/').pop() || '',
                    videoUrl: videoUrl,
                    video_state: '未生成',
                });

                // 将局部数组 videoSegments 提交到 Vuex
                context.commit('setVideoSegments', [...videoSegments]);
                console.log("当前更新的 videoSegments:", videoSegments);

                // 生成缩略图并更新状态
                await context.dispatch('generateThumbnail', { source: videoUrl, index: i });
            }

            console.log("视频片段初始化完成，开始合并视频和音频...");
            await context.dispatch('combineVideoAndAudioWithMusic');
            console.log("视频和音频合并完成");
        } catch (error) {
            logError("Failed to initialize video segments", error);
        }

    },

    // 将图片转换为视频片段并返回 Blob URL
    async createVideoFromImage(context: ActionContext<ViewEditState, any>, { imageFile, duration, outputName }: { imageFile: string; duration: number; outputName: string }) {

        try {
            console.log("开始执行 createVideoFromImage...");
            console.log(`图像文件路径: ${imageFile}, 时长: ${duration}, 输出文件名: ${outputName}`);

            const imageData = await fetchFile(imageFile);
            ffmpegInstance.FS('writeFile', 'input.png', imageData);

            const img = new Image();
            img.src = URL.createObjectURL(new Blob([imageData.buffer]));
            await new Promise((resolve) => { img.onload = resolve; });

            const scaleFilter = img.width > img.height ? `scale=-1:720` : `scale=1280:-1`;
            await ffmpegInstance.run('-loop', '1', '-i', 'input.png', '-t', `${duration}`, '-vf', scaleFilter, '-pix_fmt', 'yuv420p', '-c:v', 'libx264', outputName);
            console.log(`FFmpeg 命令执行成功，输出文件: ${outputName}`);

            const data = ffmpegInstance.FS('readFile', outputName);
            if (!data) {
                console.error(`未能读取输出文件: ${outputName}`);
                return '';
            }

            const videoBlob = new Blob([data.buffer], { type: 'video/mp4' });
            return URL.createObjectURL(videoBlob);
        } catch (error) {
            console.error(`未能从图像创建视频: ${outputName}`, error);
            return '';
        }

    },

    // 生成视频缩略图并更新状态
    async generateThumbnail(context: ActionContext<ViewEditState, any>, { source, index }: { source: string; index: number }) {
        try {
            const video = document.createElement('video');
            video.src = source;
            video.muted = true;

            return new Promise((resolve) => {
                video.addEventListener('loadedmetadata', () => {
                    video.currentTime = 1; // 跳转到1秒位置
                });

                video.addEventListener('loadeddata', () => {
                    setTimeout(() => {
                        const videoAspectRatio = video.videoWidth / video.videoHeight;
                        const targetHeight = 200;
                        const targetWidth = targetHeight * videoAspectRatio;

                        const canvas = document.createElement('canvas');
                        canvas.width = targetWidth;
                        canvas.height = targetHeight;

                        const ctx = canvas.getContext('2d');
                        if (ctx) {
                            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                            const thumbnail = canvas.toDataURL();
                            context.commit('updateThumbnail', { index, thumbnail });
                            resolve(thumbnail);
                        } else {
                            console.error("Canvas context not available.");
                            resolve('');
                        }
                    }, 300);
                });

                video.addEventListener('error', (e) => {
                    console.error("Error loading video for thumbnail generation:", e);
                    resolve('');
                });
            });
        } catch (error) {
            console.error("Failed to generate thumbnail", error);
        }
    },


    // 合并所有视频片段到一个视频
    async mergeVideoSegments(context: ActionContext<ViewEditState, any>) {

        console.log("开始执行 mergeVideoSegments 函数...");

        const sources = context.state.sources;
        if (sources.length === 0) {
            throw new Error("sources 列表为空，无法合并视频片段");
        }

        for (let i = 0; i < sources.length; i++) {
            console.log(`获取视频片段: ${sources[i]}`);
            const response = await fetch(sources[i]);
            if (!response.ok) throw new Error(`视频片段获取失败: ${sources[i]}`);

            const videoBlob = await response.blob();
            console.log(`视频片段 ${i} 加载成功, 大小: ${videoBlob.size} 字节`);
            ffmpegInstance.FS('writeFile', `${i}.mp4`, await fetchFile(videoBlob));
        }

        const concatList = sources.map((_, index) => `file '${index}.mp4'`).join('\n');
        ffmpegInstance.FS('writeFile', 'concat.txt', new TextEncoder().encode(concatList));

        try {
            console.log("运行 FFmpeg 合并视频片段...");
            await ffmpegInstance.run('-f', 'concat', '-safe', '0', '-i', 'concat.txt', '-c', 'copy', 'merged_video.mp4');
            console.log("视频片段合并成功");
        } catch (error) {
            console.error("FFmpeg 合并视频片段失败", error);
            throw error;
        }

        const data = ffmpegInstance.FS('readFile', 'merged_video.mp4');
        const videoBlob = new Blob([data.buffer], { type: 'video/mp4' });
        return URL.createObjectURL(videoBlob);

    },

    // 合并视频、旁白音频和背景音乐
    async combineVideoAndAudioWithMusic(context: ActionContext<ViewEditState, any>) {
        console.log("开始执行 combineVideoAndAudioWithMusic 函数...");

        const audioUrl = context.state.generatedAudioUrl;
        const musicUrl = context.state.musicUrl;
        if (!audioUrl) throw new Error("旁白音频 URL 未提供。");
        if (!musicUrl) throw new Error("背景音乐 URL 未提供。");

        // 合并视频和旁白音频
        const mergedVideoUrl = await context.dispatch('mergeVideoSegments');
        const videoBlob = await fetch(mergedVideoUrl).then(response => response.blob());
        const videoData = await fetchFile(videoBlob);
        ffmpegInstance.FS('writeFile', 'merged_video.mp4', videoData);

        const audioData = await fetchFile(audioUrl);
        ffmpegInstance.FS('writeFile', 'narration.mp3', audioData);

        await ffmpegInstance.run('-stream_loop', '-1', '-i', 'merged_video.mp4', '-i', 'narration.mp3', '-c:v', 'copy', '-c:a', 'aac', '-shortest', 'video_with_audio.mp4');

        console.log("视频、旁白音频合并成功");

        // 读取合并后的初步视频数据
        const tempVideoData = ffmpegInstance.FS('readFile', 'video_with_audio.mp4');
        ffmpegInstance.FS('writeFile', 'temp_video_with_audio.mp4', tempVideoData);

        // 加入背景音乐
        const musicData = await fetchFile(musicUrl);
        ffmpegInstance.FS('writeFile', 'background_music.mp3', musicData);

        // 使用原视频的长度将背景音乐合并进视频
        await ffmpegInstance.run('-i', 'temp_video_with_audio.mp4', '-stream_loop', '-1', '-i', 'background_music.mp3', '-filter_complex', '[1:a]volume=0.3[a1];[0:a][a1]amix=inputs=2:duration=shortest', '-shortest', '-c:v', 'copy', 'final_output_with_music.mp4');

        console.log("视频、旁白音频和背景音乐合并成功");

        const finalData = ffmpegInstance.FS('readFile', 'final_output_with_music.mp4');
        const finalVideoBlob = new Blob([finalData.buffer], { type: 'video/mp4' });
        const combinedVideoUrl = URL.createObjectURL(finalVideoBlob);

        context.commit('setCombinedVideoUrl', combinedVideoUrl);
        console.log("最终合成视频 URL（包含背景音乐）已提交到 Vuex 状态中。");
    },

    // 启动视频帧绘制到 Canvas
    startDrawingFrames({ state, commit }: ActionContext<ViewEditState, any>) {
        const canvas = state.canvas;
        const player = state.player;

        if (!player || !canvas) return;

        const ctx = canvas.getContext('2d')!;
        const drawFrame = () => {
            // 在每次绘制前确认 canvas 和 player 都存在且有效
            if (player && !player.paused && !player.ended && canvas) {
                ctx.drawImage(player, 0, 0, canvas.width, canvas.height);
                // 请求下一帧并保存 ID
                const animationId = requestAnimationFrame(drawFrame);
                commit('setAnimationFrameId', animationId);
            }
        };

        // 启动第一次绘制
        const animationId = requestAnimationFrame(drawFrame);
        commit('setAnimationFrameId', animationId);
    },

    // 停止绘制帧
    stopDrawingFrames({ state, commit }: ActionContext<ViewEditState, any>) {
        if (state.animationFrameId !== null) {
            cancelAnimationFrame(state.animationFrameId); // 取消已请求的动画帧
            commit('setAnimationFrameId', null); // 清除保存的 ID
        }
    },

    async generateVideo(
        { commit, dispatch }: ActionContext<ViewEditState, any>,
        { videoPrompt, video_id, first_frame_image }: {
            videoPrompt: string;
            video_id: string;
            first_frame_image: string;
        }
    ) {
        // 打印 video_id 和 videoPrompt，检查传入的参数
        console.log(`Video ID: ${video_id},正在尝试生成...`);
        console.log("Video ID:", video_id);

        // 在开始时将 isGenerating 设置为 true，使用完整命名空间
        commit('ViewStoryboard/setIsGenerating', {
            id: video_id,
            isGenerating: true
        }, { root: true });  // root: true 表示在根级别查找 mutation

        // // 每隔 5 秒打印一次状态
        // const intervalId = setInterval(() => {
        //     console.log(`Video ID: ${video_id} 在正在生成...`);
        // }, 5000); // 5000 毫秒 = 5秒

        // // 在 60 秒后停止打印
        // setTimeout(() => {
        //     clearInterval(intervalId); // 清除定时器，停止继续打印
        //     console.log(`视频生成状态检查结束。Video ID: ${video_id}`);
        //     commit('ViewStoryboard/setIsGenerating', {
        //         id: video_id,
        //         isGenerating: false
        //     }, { root: true });  // root: true 表示在根级别查找 mutation
        // }, 60000); // 60000 毫秒 = 60秒

        return new Promise<void>(async (resolve, reject) => {
            try {
                // 设置视频生成请求的数据
                const videoData = {
                    video_id: video_id,
                    prompt: videoPrompt,
                    model: 'video-01',
                    first_frame_image: first_frame_image,
                };

                // 调用 API 生成视频，获取 taskId
                const response = await invokeVideoGeneration(videoData);
                const taskId = response.task_id;

                if (!taskId) throw new Error("未获取到任务ID");

                // 更新任务状态为进行中
                commit('setVideoSegmentStatus', { video_id, status: '进行中' });
                console.log(`生成视频任务已启动，任务ID: ${taskId}`);

                // 轮询任务状态，直到生成成功
                const checkStatus = async () => {
                    const result = await queryVideoGeneration(taskId);
                    console.log(`视频生成任务状态: ${result.task_status}`);

                    if (result.task_status === 'Success') {
                        // 获取生成视频的 file_url
                        const fileUrl = result.file_url;

                        try {
                            // 下载视频并转换为 Blob
                            const blobResponse = await fetch(fileUrl);
                            const blob = await blobResponse.blob();
                            const blobUrl = URL.createObjectURL(blob);

                            // 将 Blob URL 替换到 videoSegment 中
                            commit('setVideoSegmentStatus', { video_id, file_url: blobUrl, status: 'success' });
                            console.log("视频生成成功并下载完成，Blob URL:", blobUrl);
                        } catch (downloadError) {
                            console.error("下载视频文件失败:", downloadError);
                            commit('setVideoSegmentStatus', { video_id, status: '下载失败' });
                        }
                        // 生成完成后的逻辑
                        resolve(); // 轮询成功完成，通知外部

                        // 更新 videoSegments 的 sources
                        console.log(state.sources);
                        commit('updateVideoSegments');
                        console.log("更新后的sources", state.sources);

                        // 调用 combineVideoAndAudioWithMusic
                        await dispatch('combineVideoAndAudioWithMusic'); // 修改这里

                    } else if (result.task_status === 'Processing') {
                        console.log(`Video ID: ${video_id}在正在生成"`);
                        setTimeout(checkStatus, 3000); // 每隔3秒检查状态
                    } else {
                        commit('setVideoSegmentStatus', { video_id, status: '生成失败' });
                        console.error("视频生成失败或任务未完成:", result.task_status);
                    }
                };

                // 调用轮询检查
                checkStatus();
            } catch (error) {

                commit('ViewStoryboard/setIsGenerating', {
                    id: video_id,
                    isGenerating: false
                }, { root: true });  // root: true 表示在根级别查找 mutation

                commit('setTaskStatus', 'error');
                console.error("生成视频失败:", error);
                reject(error); // 捕获错误时通知外部
            }

            // 最终设置 isGenerating 为 false，确保无论成功还是失败都被执行
            finally {
                commit('ViewStoryboard/setIsGenerating', {
                    id: video_id,
                    isGenerating: false
                }, { root: true });  // root: true 表示在根级别查找 mutation
                console.log(`视频生成过程已结束，Video ID: ${video_id}`);
            }
        });
    },

    // 更新视频片段状态
    async updateSegments(context: ActionContext<ViewEditState, any>) {


    },


    // 获取所有素材 URL 的方法
    getAllAssetUrls({ state }: { state: ViewEditState }) {
        const videoUrls = state.videoSegments.map(segment => segment.videoUrl).filter(url => !!url);
        const { musicUrl, generatedAudioUrl, combinedVideoUrl } = state;

        // 将所有 URL 返回
        return {
            videoUrls,
            musicUrl,
            generatedAudioUrl,
            combinedVideoUrl
        };
    },
}

// 将 state、mutations、getters 和 actions 合并到模块中
export default{
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
