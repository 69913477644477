// store/modules/advertising.ts
import { Module } from 'vuex';

export interface TrailerFormState {
    form: {
        companyName: string;
        companyTitle: string;
        videoDuration: string;
        companyIntro: string,
        productIntro: string;
        useCases: string;
        serviceIntro: string;
        advantages: string;
        teamIntro: string;
        contact: string;
        reference: string;
    };
    errors: Record<string, boolean>;
}

const trailerForm: Module<TrailerFormState, any> = {
    namespaced: true,
    state: {
        form: {
            companyName: '',
            companyTitle: '',
            videoDuration: '',
            companyIntro: '',
            productIntro: '',
            useCases: '',
            serviceIntro: '',
            advantages: '',
            teamIntro: '',
            contact: '',
            reference: '',
        },
        errors: {},
    },
    mutations: {
        setField(state, { field, value }: { field: keyof TrailerFormState['form']; value: string }) {
            state.form[field] = value;
        },
        setError(state, { field, hasError }: { field: keyof TrailerFormState['form']; hasError: boolean }) {
            state.errors[field] = hasError;
        },
        setForm(state, formData: TrailerFormState['form']) {
            state.form = formData;
        },
        resetForm(state) {
            state.form = {
                companyName: '',
                companyTitle: '',
                videoDuration: '',
                companyIntro: '',
                productIntro: '',
                useCases: '',
                serviceIntro: '',
                advantages: '',
                teamIntro: '',
                contact: '',
                reference: '',
            };
            state.errors = {};
        },
    },
    actions: {
        updateField({ commit }, payload: { field: keyof TrailerFormState['form']; value: string }) {
            commit('setField', payload);
        },
        validateForm({ commit, state }) {
            const requiredFields: Array<keyof TrailerFormState['form']> = ['companyName', 'companyTitle', 'videoDuration'];
            let isValid = true;

            requiredFields.forEach((field) => {
                const hasError = !state.form[field];
                commit('setError', { field, hasError });
                if (hasError) isValid = false;
            });

            if (isNaN(Number(state.form.videoDuration)) || Number(state.form.videoDuration) <= 0) {
                commit('setError', { field: 'videoDuration', hasError: true });
                isValid = false;
            }

            return isValid;
        },
    },
    getters: {
        formData: (state) => state.form,
        errors: (state) => state.errors,
    },
};

export default trailerForm;
