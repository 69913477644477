// store/modules/plan.ts

import { getSession, chatWithBot } from '@/api/plan'; // 引入封装的 API 方法

const state = {
    scriptType: '',  // 当前制作的片子类型 (广告片或宣传片)
    videoData: {
        id: '',
        type: '',
        icon: '', // 假设这里是存储icon路径的字段
        title: '',
    },   // 视频卡片的数据 (type, icon, title等)
    isCorporateViewVisible: false, // 是否显示表单
    selectedTitle: '', // 当前选择的视频卡片标题

    bot_id: '',
    conversation_id: '',

    formData: {},  //用来存储当前表单的值

    currentPage: 0, // 当前页码
    pages: [ // 每一页的内容和对应的修改意见
        {
            content: `
- **产品卖点分析**：
1. 出色的外观设计：小米SU7采用经典溜背轿跑式造型，低风阻系数，独特的灯光设计，极具辨识度。
2. 强劲的性能表现：多种动力版本，优秀的动力系统，长续航能力，超越绝大多数竞品。
3. 领先的智能配置：澎湃智能座舱，智能驾驶辅助系统，全系标配智能辅助驾驶。
          
- **广告策略**：
- 广告整体方向和目标：通过视觉美感和冲击力，展示小米SU7的外观设计、性能和智能配置，吸引年轻一代、科技爱好者、追求性价比的城市白领、小米品牌忠实粉丝、高学历人群和女性消费者。主题围绕“让每一次出行都成为享受！”。
          
- **广告创意一**：
- 创意点和表现形式：视频开场是一辆小米SU7在城市夜景中穿梭，灯光效果极其炫目。镜头切换到车内，展示澎湃智能座舱和智能驾驶辅助系统。随后，车辆加速驶向郊外，展示其强劲的性能和长续航能力。视频结尾，车辆停在美丽的自然风景前，车主微笑着下车，享受美好时光。旁白：“小米SU7，让每一次出行都成为享受！”
          
- **广告创意二**：
- 创意点和表现形式：视频以一位年轻女性科技爱好者的视角展开，她在城市中驾驶小米SU7，体验智能座舱和智能驾驶辅助系统。她在车内通过五屏联动处理工作和娱乐，展示车辆的智能配置。随后，她加速前往郊外，展示车辆的强劲性能和长续航能力。视频结尾，她在自然风景中放松，享受驾驶的乐趣。旁白：“小米SU7，科技与性能的完美结合，让每一次出行都成为享受！”
          
- **广告创意三**：
- 创意点和表现形式：视频以一位城市白领的日常生活为背景，展示他在上下班途中驾驶小米SU7。车辆在城市中穿梭，展示其出色的外观设计和低风阻系数。镜头切换到车内，展示智能座舱和智能驾驶辅助系统。随后，车辆加速驶向郊外，展示其强劲的性能和长续航能力。视频结尾，车主在美丽的自然风景中放松，享受美好时光。旁白：“小米SU7，让每一次出行都成为享受！”
          
请问您希望采用哪个创意？  `,
            input: ""
        },

    ]
};
// 在state初始化后手动计算currentPage
state.currentPage = state.pages.length - 1;

const mutations = {
    setCurrentScriptType(state: any, type: string) {
        state.scriptType = type;

        console.log(type)

        // 根据 scriptType 设置不同的 bot_id
        if (type === 'advertising') {
            state.bot_id = '7437071629068894264'; // 汽车广告片的 bot_id
        } else if (type === 'trailer') {
            state.bot_id = '7429284990443274241'; // 宣传片的 bot_id
        } else if (type === 'broadcasting') {
            state.bot_id = '7429284990443274241'; // 口播片的 bot_id
        } else {
            state.bot_id = ''; // 清空 bot_id 或设置为默认值
        }
    },

    setConversationId(state: any, conversation_id: string) {
        state.conversation_id = conversation_id;
    },

    setVideoData(state: any, payload: { type: string; icon: string; title: string }) {
        state.videoData = payload;
    },
    setIsCorporateViewVisible(state: any, payload: boolean) {
        state.isCorporateViewVisible = payload;
    },
    setSelectedTitle(state: any, payload: string) {
        state.selectedTitle = payload;
    },

    setCurrentPage(state: any, payload: number) {
        state.currentPage = payload;
    },

    setPages(state: any, pages: Array<{ content: string, input: string }>) {
        state.pages = pages;
        // 当pages更新时，将当前页面默认设置为最后一页
        state.currentPage = pages.length > 0 ? pages.length - 1 : 0;  // 确保pages有内容
    },

    addPageInput(state: any, { index, input }: { index: number, input: string }) {
        // 插入新的临时 page，并获取其序号
        const newPageIndex = state.pages.length;
        state.pages.push({
            content: "正在加载中，请稍等",  // 临时内容
            input: `针对第 ${index + 1} 号方案的修改意见为：${input}`,  // 格式化后的 input 值
        });
        state.currentPage = newPageIndex;  // 自动设置为最新页面
    },

    insertPageMutation(state: any) {
        // 插入新的临时 page，并返回其序号
        const newPageIndex = state.pages.length;
        state.pages.push({
            content: "正在加载中，请稍等",  // 临时内容
            input: "根据左侧输入做策划",  // 固定 input 值
        });
        state.currentPage = newPageIndex;  // 自动设置为最新页面
    },
    updatePageContent(state: any, { index, content }: { index: number, content: string }) {
        // 打印输入的 index
        console.log("Received index:", index);

        // 更新指定序号页面的内容
        if (state.pages[index]) {
            state.pages[index].content = content;
        } else {
            // alert("更新失败");
        }
    },
    setFormData(state: any, content: string) {
        // 将 content 保存到 formData
        state.formData = content;
    },

};


const actions = {

    async updateScriptType({ commit, state, rootGetters }: { commit: any; state: any; rootGetters: any }) {
        const userID = rootGetters['auth/userID'];
        // 确保 bot_id 存在
        if (state.bot_id) {
            try {
                const response = await getSession(state.bot_id, userID);
                if (response.data.success) {
                    commit('setConversationId', response.data.data); // 设置 conversation_id
                    console.log('Conversation ID:', response.data.data);
                } else {
                    console.error('Failed to retrieve conversation:', response.data.data);
                }
            } catch (error) {
                console.error('Error retrieving conversation:', error);
                alert(`这个AI机器人好像出了点问题，请稍后再试吧！`);
            }
        } else {
            console.error('bot_id is not set');
        }
    },

    async getPageIndex({ commit, state }: any) {
        // 计算新页面序号
        const newPageIndex = state.pages.length;

        // 调用 mutation 插入临时页面
        commit('insertPageMutation', { newPageIndex });

        // 返回新页面序号
        return newPageIndex;
    },

    getIndex({ commit, state }: any, { index, input }: { index: number, input: string }) {
        // 计算新页面序号
        const newPageIndex = state.pages.length;

        // 立即调用 mutation 插入临时页面
        commit('addPageInput', { newPageIndex, index, input });

        // 返回新页面序号和传入的 index
        return { newPageIndex, index };
    },

    async insertPage({ dispatch, commit, rootGetters, state }: { dispatch: any; commit: any; rootGetters: any; state: any }, content: string) {
        // 先插入一个临时页面，并获取该页面的序号
        const pageIndex = await dispatch('getPageIndex');

        const user_token = rootGetters['auth/token'];
        // 将 content 保存到 formData
        commit('setFormData', content);
        const user_input = JSON.stringify(content);
        // console.log(user_input)

        try {
            console.log("Before API call");
            // 调用 chatWithBot 获取流式数据的 URL
            const response = await chatWithBot({
                user_token: user_token,
                conversation_id: state.conversation_id,
                bot_id: state.bot_id,
                userInput: user_input,
                fileUrl: " "  // 可选的文件 URL
            });
            
            if (response.data.success) {
                const stream_url = response.data.stream_url;
                console.log("Stream URL:", stream_url);

                // 开始流式处理
                const eventSource = new EventSource(stream_url);

                // 用于拼接流内容
                let content_res = '';

                eventSource.onmessage = (event) => {
                    const data = JSON.parse(event.data);

                    if (data.status === 'end') {
                        // 如果流结束，关闭连接
                        eventSource.close();
                        console.log('Stream ended');
                    } else if (data.content) {
                        // 累积内容
                        content_res += data.content;

                        // 实时更新页面内容
                        commit('updatePageContent', { index: pageIndex, content: content_res });
                    }
                };

                eventSource.onerror = (error) => {
                    console.error('EventSource error:', error);
                    eventSource.close();
                };
            } else {
                console.error("Failed to initiate chat:", response.data);
            }
        } catch (error) {
            console.error("API call failed", error); // 处理错误
        }
    },


    async insertModRes({ dispatch, commit, rootGetters }: { dispatch: any; commit: any, rootGetters: any }, { index, input }: { index: number; input: string }) {
        // 调用 getIndex 并获取页面序号
        const { newPageIndex } = await dispatch('getIndex', { index, input });

        const user_token = rootGetters['auth/token'];
        try {
            console.log("Before API call");
            // 调用 chatWithBot 获取流式数据的 URL
            const response = await chatWithBot({
                user_token: user_token,
                conversation_id: state.conversation_id,
                bot_id: state.bot_id,
                userInput: input,
                fileUrl: " "  // 可选的文件 URL
            });

            if (response.data.success) {
                const stream_url = response.data.stream_url;
                console.log("Stream URL:", stream_url);

                // 开始流式处理
                const eventSource = new EventSource(stream_url);

                // 用于拼接流内容
                let content_res = '';

                eventSource.onmessage = (event) => {
                    const data = JSON.parse(event.data);

                    if (data.status === 'end') {
                        // 如果流结束，关闭连接
                        eventSource.close();
                        console.log('Stream ended');
                    } else if (data.content) {
                        // 累积内容
                        content_res += data.content;

                        // 实时更新页面内容
                        commit('updatePageContent', { index: newPageIndex, content: content_res });
                    }
                };

                eventSource.onerror = (error) => {
                    console.error('EventSource error:', error);
                    eventSource.close();
                };
            } else {
                console.error("Failed to initiate chat:", response.data);
            }
        } catch (error) {
            console.error("API call failed", error); // 处理错误
        }
    },

};



const getters = {
    getScriptType: (state: any) => state.scriptType,
    getVideoData: (state: any) => state.videoData,
    getIsCorporateViewVisible: (state: any) => state.isCorporateViewVisible,
    getSelectedTitle: (state: any) => state.selectedTitle,
    getVideoIcon: (state: any) => state.videoData.icon,

    getFormData: (state:any) => state.formData || {},

    getCurrentPageContent: (state: any) => state.pages[state.currentPage]?.content || '',
    getCurrentPageInput: (state: any) => state.pages[state.currentPage]?.input || '',
    getTotalPages: (state: any) => state.pages.length,
    getCurrentPage: (state: any) => state.currentPage

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

