// store/modules/FromBroadcasting.ts
import { Module } from 'vuex';
import axios from 'axios';
import { Store } from 'vuex';

export interface BroFormState {
    form: {
        brandName: string;
        productName: string;
        videoDuration: string;
        sellingPoint: string;
        sellingPointIntro: string;
        advertisingStyleTone: string;
        advertisingTheme: string;
        targetAudience: string;
        reference: string;
    };
    errors: Record<string, boolean>;
}

const broForm: Module<BroFormState, any> = {
    namespaced: true,
    state: {
        form: {
            brandName: '小米汽车',
            productName: '小米su7',
            videoDuration: '1分钟',
            sellingPoint: '出色的外观设计   领先的智能配置',
            sellingPointIntro: '',
            advertisingStyleTone: '视觉美感冲击力',
            advertisingTheme: '让每一次出行都成为享受！',
            targetAudience: '年轻一代、科技爱好者、追求性价比的城市白领、小米品牌的忠实粉丝',
            reference: '',
        },
        errors: {},
    },
    mutations: {
        setField(state, { field, value }: { field: keyof BroFormState['form']; value: string }) {
            state.form[field] = value;
        },
        setError(state, { field, hasError }: { field: keyof BroFormState['form']; hasError: boolean }) {
            state.errors[field] = hasError;
        },
        setForm(state, formData: BroFormState['form']) {
            state.form = formData;
        },
        resetForm(state) {
            state.form = {
                brandName: '',
                productName: '',
                videoDuration: '',
                sellingPoint: '',
                sellingPointIntro: '',
                advertisingStyleTone: '',
                advertisingTheme: '',
                targetAudience: '',
                reference: '',
            };
            state.errors = {};
        },
    },
    actions: {
        updateField({ commit }, payload: { field: keyof BroFormState['form']; value: string }) {
            commit('setField', payload);
        },
        validateForm({ state, commit }) {
            let isValid = true;
            const requiredFields: Array<keyof BroFormState['form']> = ['brandName', 'productName', 'videoDuration', 'sellingPoint'];

            requiredFields.forEach((field) => {
                const hasError = !state.form[field];
                commit('setError', { field, hasError });
                if (hasError) {
                    console.log(`${field} 为空`);
                    isValid = false;
                }
            });

            console.log('验证结果:', isValid);
            return isValid;
        },
        async submitForm({ state, commit, dispatch }) {
            const isValid = await dispatch('validateForm');
            if (!isValid) {
                return { success: false, message: 'Validation failed' };
            }

            try {
                // 使用 dispatch 调用 plan 模块的 insertPage 
                dispatch('ViewPlan/insertPage', state.form, { root: true });  // 调用命名空间 
                return { success: true };
            } catch (error) {
                // 使用 instanceof 检查 error 是否为 Error 对象
                if (error instanceof Error) {
                    return { success: false, message: error.message };
                } else {
                    // 如果 error 不是 Error 对象，则返回一个通用的错误消息
                    return { success: false, message: 'An unknown error occurred' };
                }
            }
        },

    },
    getters: {
        formData: (state) => state.form,
        errors: (state) => state.errors,
    },
};

export default broForm;
