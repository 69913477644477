import axios from 'axios';


const instance = axios.create({
  baseURL: 'https://api.aizdc.com', // 设置默认地址
  timeout: 600000,
  headers: {
    'Content-Type': 'application/json',
  },

});

export default instance;
